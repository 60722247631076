<template>
  <v-container>
    <h1 class="mb-2">Массовый поиск по Excel (артикул - количество)</h1>
      <v-btn class="mb-3" v-if="file === null || invalidFile" color="success" outlined
                           @click="getExcelExample">
                      <v-img src="@/assets/excel.svg" contain position="center"
                             class="mr-3"
                             style="width: 24px; height: 24px">

                      </v-img>
                      Эксель пример
                    </v-btn>
      <br>
    <input v-if="file === null || invalidFile" @change="onChange" type="file"/>
    <v-alert
          :value="filterAlert"
          color="error"
          dark
          border="top"
          icon="error">
        Не было выбрано ни 1 источника для поиска информации
      </v-alert>
    <div v-if="invalidFile && file !== null">
      <v-alert
          color="error"
          dark
          class="mt-4"
          border="top"
          icon="error"
      > Неподходящий формат файла, загрузите новый
      </v-alert>
    </div>
    <v-container v-if="validatedExcelData && file !== null">
      <v-row class="ma-2" align="center" justify="center">
        <v-menu offset-y
                v-if="!loading"
                content-class="mt-3"
                transition="slide-y-transition"
                :nudge-width="200"
                :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2"
                   icon
                   v-bind="attrs"
                   v-on="on">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            <v-card class="">
              <v-card-title>Тип поиска</v-card-title>
              <v-card-text>
                <v-switch
                    class="mt-0"
                    v-model="searchAll"
                    :label="searchSwitchLabel"
                    @change="filterSwitchTracker"></v-switch>
                <v-expand-transition mode="out-in">
                  <v-list v-if="!searchAll" dense class="pa-0">
                    <v-list-item v-for="(item, index) in suppliers" :key="index" class="px-0">
                      <v-list-item-action>
                        <v-checkbox @change="filterCheckboxTracker(item.supplier)"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title>{{ item.supplier }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
        <v-btn elevation="0" v-if="!loading" class="mr-2" color="primary" @click="search_request">Поиск</v-btn>
        <v-btn elevation="0" v-else class="mr-2" @click="revoke_task" dark color="danger">Отменить</v-btn>
        <v-btn elevation="0" class="mr-2" v-if="!loading" color="danger" dark @click="file=null">Выбрать другой файл</v-btn>
          <v-btn v-if="!loading" color="success" outlined
                           @click="getExcelExample">
                      <v-img src="@/assets/excel.svg" contain position="center"
                             class="mr-3"
                             style="width: 24px; height: 24px">

                      </v-img>
                      Эксель пример
                    </v-btn>
      </v-row>
      <v-data-table :headers="headers"
                    v-if="!loading"
                    :items="validatedExcelData"
                    items-per-page-text="Элементов на странице"
                    :items-per-page="validatedExcelData.length > 250 ? 250 : -1"
                    class="elevation-0"
      ></v-data-table>
      <div v-else>
        <v-progress-circular
            color="primary"
            :size="75"
            :width="7"
            :value="progress"
        >{{ progress }}%
        </v-progress-circular>
        <h3 class="pt-2">Запрос обрабатывается. Приблизительное время ожидания: 1 минута.</h3>
      </div>
      <v-container v-if="searchResp.length !== 0 && !loading">
        <h3>Результаты поиска</h3>
        <v-item-group multiple>
          <v-container>
            <v-item class="ma-2" v-for="(result, ind) in validatedSearchResult" :key="ind" v-slot="{ active, toggle }">
              <v-card :color="active ? 'primary' : 'white'">
                <v-card
                    color="transparent"
                    class="d-flex pl-2 pr-2"
                    @click="toggle"
                >
                  <h2>{{ result.name }})</h2>
                  <v-spacer/>
                  <v-icon v-if="active">
                    remove
                  </v-icon>
                  <v-icon v-else>
                    add
                  </v-icon>
                </v-card>
                <v-expand-transition mode="out-in">
                  <div
                      v-if="active"
                  >
                    <div style="height: 8px"></div>
                    <v-card class="mx-2">
                      <h3 v-if="result.data.length === 0">Ничего не найдено</h3>
                      <v-data-table v-else :headers="resHeaders"
                                    locale="ru-RU"
                                    :items="result.data"
                                    items-per-page-text="Элементов на странице"
                                    :items-per-page="result.data.length > 250 ? 250 : -1"
                                    class="elevation-0">
                        <template v-slot:[`item.img_link`]="{ item }">
                          <a href="javascript:void(0)" class="d-block" @click="onRowImageClick(item.img_link)">
                            <v-img :src="item.img_link"
                                   max-width="60"
                                   max-height="60"/>
                          </a>
                        </template>
                        <template v-slot:[`item.src_name`]="{ item }">
                          <div v-if="typeof(item.src_name)   == 'string'">
                            {{ item.src_name }}
                          </div>
                          <a target="_blank" v-else :href="item.src_name.link">
                            {{ item.src_name.name }}
                          </a>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <div>
              <span
                  v-if="checkCart(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)">
                <v-container style="width: 160px; display: flex; flex-direction: row !important">
                  <v-row justify="center" align="center">
                    <v-btn left icon @click="editQuantity(item, -1)">
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                    <span>
                      <v-form
                          @submit.prevent="editQuantityByKeyboard(item, cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity)">
                        <v-text-field class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense type="number" hide-spin-buttons
                                      v-model="cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity"/>
                          </v-form>
                      <!--                      <span class="pr-1 pl-0">-->
                      <!--                      {{-->
                      <!--                          cartElementQuantity(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)-->
                      <!--                        }}</span>-->
                    </span>
                    <v-btn
                        right icon @click="editQuantity(item, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </span>
                            <v-row style="width: 130px" justify="center" align="center" v-else>
                              <v-btn :disabled="item.quantity===0" icon class="d-flex align-center justify-center"
                                     @click="addToCart(ind, item.ind,)">
                                <v-icon>
                                  shopping_cart
                                </v-icon>
                              </v-btn>

                              <v-btn
                                  :disabled="checkWaitingList(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)"
                                  icon>
                                <v-icon @click="check_product_exists(ind, item.ind, 'waiting')">
                                  list_alt
                                </v-icon>
                              </v-btn>

                            </v-row>
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                    <div style="height: 8px"></div>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-item>
          </v-container>
        </v-item-group>
      </v-container>
    </v-container>
    <v-dialog width="400" overlay-opacity="0.1" v-model="addToWaitingListDialog"
              content-class="elevation-0" hide-overlay
              persistent :retain-focus="false">
      <v-form @submit.prevent="addToWaitingList(currentProduct)">
      <v-card outlined class="fwd">
        <v-card-title>По какому артикулу искать?</v-card-title>
        <v-card-text class="text-left pb-0">
          <v-text-field
              outlined
              dense
              prepend-inner-icon="person"
              clearable
              v-model="commonCode"
              label="Общий артикул"/>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn text class="mr-2" color="danger" dark @click="addToWaitingListDialog=false">
            Отмена
          </v-btn>
          <v-btn @click="addToWaitingList(currentProduct)" text color="success">
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <v-dialog width="400" overlay-opacity="0.1" v-model="addToCartDialog"
              content-class="elevation-0" hide-overlay
              persistent :retain-focus="false">

      <v-card outlined class="fwd">
        <v-card-title>По какому артикулу искать?</v-card-title>
        <v-card-text class="text-left pb-0">
          <v-text-field
              outlined
              dense
              prepend-inner-icon="code"
              clearable
              v-model="commonCode"
              label="Общий артикул"/>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn text class="mr-2" color="danger" dark @click="addToCartDialog=false">
            Отмена
          </v-btn>
          <v-btn @click="addToCart(currentProduct)" text color="success">
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog">
      <v-card>
        <v-img v-if="selectedImageLink" :src="selectedImageLink">

        </v-img>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>

import * as XLSX from 'xlsx'

export default {
  name: "ExcelSearchView",
  components: {},
  data() {
    return {
      selectedImageLink: null,
      imageDialog: false,
      addToWaitingListDialog: false,
      addToCartDialog: false,
      commonCode: '',
      currentProduct: '',
      cart: [],
      suppliers: [],
      waitingList: [],
      cartChanged: false,
      waitingListChanged: false,
      file: null,
      invalidFile: false,
      filterAlert: false,
      excelData: [],
      loading: false,
      taskId: null,
      progress: 0,
      parsers: 'all',
      searchAll: true,
      searchResp: [],
      resHeaders: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img_link', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'art', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src_name', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      headers: [
        {
          text: 'Артикул',
          align: 'start',
          sortable: false,
          value: 'code',
        },
        {text: 'Количество', value: 'quantity', sortable: false},
        {text: 'Цена', value: 'cost', sortable: false},
      ],
    }
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {type: 'binary'});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          this.excelData = XLSX.utils.sheet_to_json(ws, {header: 1});
        }

        reader.readAsBinaryString(this.file);
      }
    },
    search_request() {
        if (typeof this.parsers == "object" && this.parsers.length === 0) {
        this.filterAlert = true
        return
      } else {
        this.filterAlert = false
      }
      this.loading = true
      this.searchResp = []
      let search_data = {
        "search_data": this.validatedExcelData,
        "parsers": this.parsers
      }
      this.$store.dispatch('excel_search', search_data)
          .then((id) => {
            this.taskId = id
            this.search_result_request()
          })
          .catch(err => console.log(err))
    },

    revoke_task() {
      if (this.loading) {
        console.log('Revoking Task...')
        this.$store.dispatch('revoke_task', {task_id: this.taskId})
            .then((res) => {
              if (res === true) {
                this.loading = false
                this.isRevoked = res
                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => console.log(err))
      }
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    search_result_request() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('search_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.loading = false
                this.progress = 0
                this.searchResp = res
                console.log(this.searchResp)
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.search_result_request())
      }
    },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    cartElementQuantityIndex(name, code, srcName) {
      let res = this.cart.findIndex(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res
    },
    addToCart(outInd, inInd) {
      console.log(this.searchResp[outInd].data[inInd - 1])
      let product_data = {'product_data': this.searchResp[outInd].data[inInd - 1]}
      this.$store.dispatch('add_to_cart', product_data)
          .then((resp) => {
            console.log(resp)
            this.cartChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    //
    // addToWaitingList(outInd, inInd) {
    //   console.log(this.searchResp[outInd].data[inInd - 1])
    //   let product_data = {'product_data': this.searchResp[outInd].data[inInd - 1]}
    //   this.$store.dispatch('add_to_waiting_list', product_data)
    //       .then((resp) => {
    //         console.log(resp)
    //         this.waitingListChanged = true
    //       })
    //       .catch(err => {
    //         this.loading = false
    //         console.log(err)
    //       })
    // },
    // addToCart(product_data) {
    //   if (this.commonCode.length !== 0) {
    //     this.addToCartDialog = false
    //     this.currentProduct = null
    //     product_data['product_data'].common_code = this.commonCode
    //     this.commonCode = ''
    //   }
    //   this.$store.dispatch('add_to_cart', product_data)
    //       .then((resp) => {
    //         console.log(resp)
    //         this.cartChanged = true
    //       })
    //       .catch(err => {
    //         this.loading = false
    //         console.log(err)
    //       })
    // },

    addToWaitingList(product_data) {
      if (this.commonCode.length !== 0) {
        this.addToWaitingListDialog = false
        this.currentProduct = null
        product_data['product_data'].common_code = this.commonCode
        this.commonCode = ''
      }
      this.$store.dispatch('add_to_waiting_list', product_data)
          .then((resp) => {
            console.log(resp)
            this.waitingListChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    getExcelExample() {
      this.$store.dispatch('excel_example')
          .then((resp) => {
            this.exportResp = resp
          })
          .catch(err => {
            console.log(err)
          })
    },
    filterCheckboxTracker(supplier) {
      if (this.parsers.includes(supplier)) this.parsers.splice(this.parsers.indexOf(supplier), 1)
      else this.parsers.push(supplier)
      console.log(this.parsers)
    },
    check_product_exists(outInd, inInd, dialog_type) {
      console.log(this.searchResp[outInd].data[inInd - 1])
      let product_data = {'product_data': this.searchResp[outInd].data[inInd - 1]}
      this.$store.dispatch('check_product_exists', product_data)
          .then((resp) => {
            console.log(resp)
            if (resp['status']) {
              if (dialog_type === 'waiting') this.addToWaitingList(product_data)
              if (dialog_type === 'cart') this.addToCart(product_data)

            } else {
              this.currentProduct = product_data
              if (dialog_type === 'waiting') this.addToWaitingListDialog = true
              if (dialog_type === 'cart') this.addToCartDialog = true
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    getCartProductByName(name, code, src) {
      return this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    getWaitingListProductByName(name, code, src) {
      return this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    isNothingToAdd(item) {
      return this.getCartProductByName(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)[0].quantity >= item.quantity
    },
    isNothingToAddToWaitingList(item) {
      return this.getWaitingListProductByName(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)[0].quantity >= item.quantity
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then((res) => {
                  this.cart = res
                  console.log(res)
                })
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then((res) => {
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantityWaitingList(product, change) {
      let cartProduct = this.getWaitingListProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_waitingListProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('waiting_list_short_info')
                .then((res) => {
                  this.waitingList = res
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },

    checkCart(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    checkWaitingList(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },

    cartElementQuantity(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },
    waitingListElementQuantity(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },

    filterSwitchTracker(cond) {
      if (cond) this.parsers = 'all'
      else this.parsers = []
    },

    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
  },
  computed: {
      searchSwitchLabel() {
      return this.searchAll ? 'По всем' : 'По выбранным'
    },
    validatedExcelData() {
      let excelData = JSON.parse(JSON.stringify(this.excelData));
      let data = []
      if (excelData.length < 3) return false
      else {
        if (excelData[0][0].toLowerCase() !== 'артикул' || excelData[0][1].toLowerCase() !== 'количество' || excelData[0][2].toLowerCase() !== 'цена') {
          return false
        } else {
          excelData.splice(0, 1)
          excelData.forEach(element => {
            if (element[0] && element[1]) {
              data.push({code: String(element[0]), quantity: element[1], cost: element[2] || null})
            }
          })
          return data
        }
      }
    },
    validatedSearchResult() {
      if (this.searchResp.length !== 0) {
        let validated = JSON.parse(JSON.stringify(this.searchResp));
        validated.forEach(result => {
          let ind = 1
          if(!result.data) result.data = []
          result.data.forEach(element => {
            element.ind = ind
            element.cost = parseFloat(element.cost).toFixed(2)
            switch (element.availability) {
              case true:
                element.availability = 'В наличии'
                break
              case false:
                element.availability = 'Нет в наличии'
                break
              case null:
                element.availability = 'Нет информации'
                break
            }
            if (element.quantity === -1) {
              element.quantity = 'Много'
            }
            switch (element.currency) {
              case 'RUB':
                element.currency = 'Рубли'
                break
              case 'USD':
                element.currency = 'Доллары'
                break
              case 'EUR':
                element.currency = 'Евро'
                break
            }
            ind++
          })
        })
        return validated
      }
      return []
    }
  },
  watch: {
    validatedExcelData: function (val) {
      if (val === false) this.invalidFile = true
      else this.invalidFile = false
    },
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
    cartChanged: function (val) {
      if (val) {
        this.$store.dispatch('cart_short_info')
            .then((res) => {
              this.cart = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.cartChanged = false
      }
    },
    waitingListChanged: function (val) {
      if (val) {
        this.$store.dispatch('waiting_list_short_info')
            .then((res) => {
              this.waitingList = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.waitingListChanged = false
      }
    }
  },
  created() {
    console.log('Getting suppliers info...')
    this.$store.dispatch('suppliers_info')
        .then((res) => {
          this.suppliers = res
          console.log(res)
        })
        .catch(err => console.log(err))
    this.cart = this.$store.state.cartShortInfo
    this.waitingList = this.$store.state.waitingListShortInfo
  }
}
</script>

<style scoped>

</style>