<template>
  <v-container>
    <h1 v-if="shortWaitingList.length === 0">Лист ожидания пуст</h1>
    <v-container v-else>
      <h1>Лист ожидания </h1>
      <v-dialog width="400" overlay-opacity="0.1" v-model="clearDialog"
                content-class="elevation-0"
                persistent :retain-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" v-bind="attrs" v-on="on" elevation="0" dark v-if="!loading" color="danger">Очистить
            лист ожидания
          </v-btn>
        </template>
        <v-card class="fwd">
          <v-card-title>Подтвердите действие</v-card-title>
          <v-card-text class="text-left pb-0">Вы действительно хотите очистить лист ожидания?
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-end">
            <v-btn text class="mr-2" color="danger" dark @click="clearDialog=false">
              Нет
            </v-btn>
            <v-btn text color="success" @click="this.clearDialog = false; waiting_list_clear_request">
              Да
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn v-if="!loading" color="primary" @click="waiting_list_refresh_request" dark class="mx-1">Обновить лист
        ожидания
      </v-btn>
      <v-btn v-if="loading" @click="revoke_task" color="danger" dark class="mx-1">Отменить</v-btn>
    </v-container>
    <v-container v-if="loading">
      <v-progress-circular
          color="primary"
          :size="75"
          :width="7"
          :value="progress"
      >{{ progress }}%
      </v-progress-circular>
      <h3 class="pt-2">Запрос обрабатывается. Приблизительное время ожидания: 1 минута.</h3>
    </v-container>
    <v-container v-if="shortWaitingList.length !== 0 && !loading">

      <v-item-group multiple>
        <v-container>
          <v-item class="mb-2" v-for="(products, key, ind) in validatedWaitingListTableByPage" :key="ind"
                  v-slot="{ active, toggle }">
            <v-card :color="active ? 'primary' : 'white'">
              <v-card
                  color="transparent"
                  class="d-flex pl-2 pr-2"
                  @click="toggle"
              >
                <h2 :class="{'success--text': get_header_color(products), 'black--text': !get_header_color(products)}">
                  {{ key }} (Общий артикул: {{ waitingList[key][0].common_code }})</h2>
                <v-spacer/>
                <v-icon v-if="active">
                  remove
                </v-icon>
                <v-icon v-else>
                  add
                </v-icon>
              </v-card>
              <v-expand-transition mode="out-in">
                <div
                    v-if="active"
                >
                  <v-row align="center" justify="center" class="mt-2 mb-0">
                    <v-dialog width="400" overlay-opacity="0.1" v-model="deleteDialog" content-class="elevation-0"
                              persistent :retain-focus="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" color="danger" elevation="0" dark v-bind="attrs"
                               @click="objectToDeleteId=waitingList[key][0].id" v-on="on">
                          Удалить
                        </v-btn>
                      </template>
                      <v-card class="fwd">
                        <v-card-title>Подтвердите действие</v-card-title>
                        <v-card-text class="text-left pb-0">Вы действительно хотите удалить "{{ key }}"
                          из листа
                          ожидания?
                        </v-card-text>
                        <v-card-actions class="d-flex align-center justify-end">
                          <v-btn text class="mr-2" color="danger" dark @click="deleteDialog=false">
                            Нет
                          </v-btn>
                          <v-btn text color="success" @click="deleteWaitingListProduct(objectToDeleteId)">
                            Да
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn class="mx-1" elevation="0" color="success"
                           @click="waitingListProduct_refresh_request(waitingList[key][0].id)">
                      Обновить
                    </v-btn>
                  </v-row>
                  <div style="height: 8px"></div>
                  <v-card class="mx-2">
                    <h3 v-if="products.length === 0">На данный момент здесь пусто</h3>
                    <v-data-table :headers="headers"
                                  v-if="products.length !== 0"
                                  locale="ru-RU"
                                  :items="products"
                                  items-per-page-text="Элементов на странице"
                                  :items-per-page="products.length > 250 ? 250 : -1"
                                  class="elevation-0">
                      <template v-slot:[`item.img`]="{ item }">
                        <a href="javascript:void(0)" class="d-block" @click="onRowImageClick(item.img)">
                          <v-img :src="item.img"
                                 max-width="60"
                                 max-height="60"/>
                        </a>
                      </template>
                      <template v-slot:[`item.src`]="{ item }">
                        <div v-if="typeof(item.src)   == 'string'">
                          {{ item.src }}
                        </div>
                        <a target="_blank" v-else :href="item.src.link">
                          {{ item.src.name }}
                        </a>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <div>
              <span
                  v-if="checkCart(item.name, item.code, typeof(item.src)   == 'string' ? item.src : item.src.name)">
                <v-container style="width: 150px; display: flex; flex-direction: row !important">
                  <v-row justify="center" align="center">
                    <v-btn left icon @click="editQuantity(item, -1)">
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                    <span>
                      <v-form
                          @submit.prevent="editQuantityByKeyboard(item, shortCart[cartElementQuantityIndex(item.name, item.code, typeof (item.src) == 'string' ? item.src : item.src.name)].quantity)">
                        <v-text-field class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense type="number" hide-spin-buttons
                                      v-model="shortCart[cartElementQuantityIndex(item.name, item.code, typeof (item.src) == 'string' ? item.src : item.src.name)].quantity"/>
                          </v-form>
                      <!--                      <span class="pr-1 pl-0">-->
                      <!--                      {{-->
                      <!--                          cartElementQuantity(item.name, item.code, typeof (item.src) == 'string' ? item.src : item.src.name)-->
                      <!--                        }}</span>-->
                    </span>
                    <v-btn
                        right icon @click="editQuantity(item, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </span>
                          <v-row style="width: 130px" justify="center" align="center" v-else>
                            <v-btn :disabled="item.quantity===0" icon class="d-flex align-center justify-center"
                                   @click="addToCart(key, item.ind)">
                              <v-icon>
                                shopping_cart
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card>
                  <div style="height: 8px"></div>
                </div>
              </v-expand-transition>
            </v-card>
          </v-item>
        </v-container>
      </v-item-group>
    </v-container>
    <pagination-view @pageChanged="page_changed" :elementsCount="shortWaitingList.length" :currentPage="currentPage"/>
  </v-container>
</template>

<script>
import PaginationView from "@/components/PaginationView";

export default {
  name: "NewWaitingListView",
  components: {PaginationView},
  data() {
    return {
      waitingList: this.$store.state.waitingListFullInfo,
      shortWaitingList: this.$store.state.waitingListShortInfo,
      shortCart: this.$store.state.cartShortInfo,
      selectedImageLink: null,
      imageDialog: false,
      loading: false,
      cartChanged: false,
      deleteDialog: false,
      clearDialog: false,
      waitingListRefreshResp: [],
      clearStatus: null,
      waitingListProductRefreshResp: null,
      objectToDeleteId: null,
      taskId: null,
      currentPage: 1,
      progress: 0,
      headers: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'code', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    get_waiting_list_full_info() {
      this.$store.dispatch('waiting_list_full_info')
          .then((res) => {
            console.log('HERE')
            console.log(res)
            this.waitingList = res
          })
          .catch(err => console.log(err))
    },
    page_changed(e) {
      this.currentPage = e
    },
    get_data_by_page() {
      // console.log('BY PAGE')
      // console.log(this.validatedWaitingListTable.slice((this.currentPage-1)*10, this.currentPage*10))
      return this.validatedWaitingListTable.slice((this.currentPage - 1) * 10, this.currentPage * 10)
    },
    revoke_task() {
      if (this.loading) {
        console.log('Revoking Task...')
        this.$store.dispatch('revoke_task', {task_id: this.taskId})
            .then((res) => {
              if (res === true) {
                this.loading = false
                this.isRevoked = res
                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => console.log(err))
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    waiting_list_refresh_request() {
      this.loading = true
      this.waitingListRefreshResp = []
      this.$store.dispatch('refresh_waiting_list')
          .then((id) => {
            this.taskId = id
            this.waiting_list_refresh_result_request()
          })
          .catch(err => console.log(err))
    },
    waiting_list_refresh_result_request() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('refresh_waiting_list_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.$store.dispatch('waiting_list_short_info')
                    .then(resp => this.shortWaitingList = resp)
                    .catch(err => console.log(err))

                this.$store.dispatch('waiting_list_full_info')
                    .then(resp => this.waitingList = resp)
                    .catch(err => console.log(err))
                this.loading = false
                this.progress = 0
                this.waitingListRefreshResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.waiting_list_refresh_result_request())
      }
      // setTimeout(this.cart_refresh_result_request, 2000)
      // }
    },
    addToCart(key, inInd) {
      let product_data = {'product_data': this.waitingList[key][inInd].data}
      console.log(product_data)
      this.$store.dispatch('add_to_cart', product_data)
          .then((resp) => {
            console.log(resp)
            console.log('Product was added to cart')
            this.cartChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    formatString(datetime) {
      let y = new Intl.DateTimeFormat('ru', {year: 'numeric'}).format(datetime);
      let m = new Intl.DateTimeFormat('ru', {month: '2-digit'}).format(datetime);
      let d = new Intl.DateTimeFormat('ru', {day: '2-digit'}).format(datetime);
      let H = new Intl.DateTimeFormat('ru', {hour: '2-digit'}).format(datetime);
      let M = new Intl.DateTimeFormat('ru', {minute: '2-digit'}).format(datetime);
      if (M.length === 1) M = '0' + M
      return `${d}.${m}.${y} ${H}:${M}`
    },
    getCartProductByName(name, code, src) {
      return this.shortCart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    getWaitingListProductByName(name, code, src) {
      return this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    waitingListProduct_refresh_request(id) {
      this.loading = true
      this.$store.dispatch('refresh_waitingListProduct', {'id': id})
          .then((id) => {
            this.taskId = id
            this.waitingListProduct_refresh_result()
          })
          .catch(err => console.log(err))
    },
    waitingListProduct_refresh_result() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('refresh_waitingListProduct_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.$store.dispatch('cart_short_info')
                    .then(resp => this.shortCart = resp)
                    .catch(err => console.log(err))

                this.$store.dispatch('cart_full_info')
                    .then(resp => this.cart = resp)
                    .catch(err => console.log(err))
                this.loading = false
                this.progress = 0
                this.waitingListProductRefreshResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.waitingListProduct_refresh_result())
      }
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then((res) => {
                  this.shortCart = res
                  console.log(res)
                })
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then((res) => {
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    cartElementQuantityIndex(name, code, srcName) {
      let res = this.shortCart.findIndex(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res
    },
    checkCart(name, code, srcName) {
      let res = this.shortCart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    waiting_list_clear_request() {

      this.$store.dispatch('clear_waiting_list')
          .then((status) => {
            this.clearStatus = status
            this.clearDialog = true
            this.$store.dispatch('waiting_list_short_info')
                .then(resp => this.shortWaitingList = resp)
                .catch(err => console.log(err))
            this.$store.dispatch('waiting_list_full_info')
                .then(resp => this.waitingList = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    checkWaitingList(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    cartElementQuantity(name, code, srcName) {
      let res = this.shortCart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
    get_header_color(products) {
      let flag = false
      products.forEach(prod => {
        if (prod.availability === 'В наличии') {
          flag = true
        }
      })
      return flag
    },
    deleteWaitingListProduct(product_id) {
      this.$store.dispatch('delete_waitingListProduct', {
        'id': product_id,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.$store.dispatch('waiting_list_short_info')
                .then(resp => {
                  this.shortWaitingList = resp
                  let maxPage = Math.floor(this.shortWaitingList.length / 10)
                  if (this.shortWaitingList.length % 10 !== 0) maxPage += 1
                  if(this.currentPage > maxPage) this.currentPage-=1
                })
                .catch(err => console.log(err))

            this.$store.dispatch('waiting_list_full_info')
                .then(resp => this.waitingList = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
  },
  computed: {
    validatedWaitingList() {
      if (this.waitingList.length !== 0) {
        let waitingList = JSON.parse(JSON.stringify(this.waitingList));
        for (const [key] of Object.entries(waitingList)) {
          // if (key !== 'extra') {
          let ind = 0
          waitingList[key].forEach(element => {
            if (ind === 0) {
              ind++
            } else {
              element = element.data
              element.ind = ind
              element.cost = parseFloat(element.cost).toFixed(2)
              switch (element.availability) {
                case true:
                  element.availability = 'В наличии'
                  break
                case false:
                  element.availability = 'Нет в наличии'
                  break
                case null:
                  element.availability = 'Нет информации'
                  break
              }
              if (element.quantity === -1) {
                element.quantity = 'Много'
              }
              switch (element.currency) {
                case 'RUB':
                  element.currency = 'Рубли'
                  break
                case 'USD':
                  element.currency = 'Доллары'
                  break
                case 'EUR':
                  element.currency = 'Евро'
                  break
              }
              ind++
            }
          })
          // }
        }
        console.log('Validated')
        console.log(waitingList)
        return waitingList
      }
      return []
    },
    validatedWaitingListTableByPage() {
      return Object.keys(this.validatedWaitingListTable).slice((this.currentPage - 1) * 10, (this.currentPage) * 10).reduce((result, key) => {
        result[key] = this.validatedWaitingListTable[key];
        return result;
      }, {});

    },
    validatedWaitingListTable() {
      let data = {}
      for (const [key] of Object.entries(this.waitingList)) {
        data[key] = []
        let flag = true
        this.validatedWaitingList[key].forEach(element => {
          if (flag) {
            flag = false
          } else {
            let temp = {}
            temp.ind = element.data.ind
            temp.name = element.name
            temp.code = element.code
            temp.img = element.data.img_link
            temp.cost = element.data.cost
            temp.currency = element.data.currency
            temp.availability = element.data.availability
            temp.quantity = element.data.quantity
            temp.src = element.data.src_name
            data[key].push(temp)
          }
        })
      }
      console.log(data)
      return data
    },
  },
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
    cartChanged: function (val) {
      if (val) {
        this.$store.dispatch('cart_short_info')
            .then((res) => {
              console.log('Cart Changed and refreshed')
              this.shortCart = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.cartChanged = false
      }
    },
    waitingListChanged: function (val) {
      if (val) {
        this.$store.dispatch('waiting_list_short_info')
            .then((res) => {
              this.waitingList = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.waitingListChanged = false
      }
    }
  },
  mounted() {
    this.get_waiting_list_full_info()
  }
}
</script>

<style scoped>

</style>