<template>
  <v-app>
    <v-main>
  <div class="fhd fwd d-flex align-center justify-center">
    <v-form  @submit.prevent="get_hash">
      <v-card max-width="400">
        <v-card-title>
          Хэш
        </v-card-title>
        <v-card-text>
          <FileInputView @file="process_file"/>
          <v-text-field outlined
                        dense hide-details
                        prepend-inner-icon="key"
                        clearable
                        class="pt-2"
                        v-model="key"
                        label="Ключ"
                        type="password"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="primary" type="submit">
            Сгенерировать Хэш
            <v-icon right>tag</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <div>
      {{hash}}
    </div>
  </div>
      </v-main>
  </v-app>
</template>

<script>
import FileInputView from "../components/FileInputView";

export default {
  name: "HashPage",
  components: {FileInputView},
  data() {
    return {
      file: null,
      key: '',
      hash: ''
    }
  },
  methods: {
    process_file(obj) {
      console.log("proc file")
      if (obj?.res) {
        this.file = obj.res
      } else {
        this.file = null
      }
    },
    get_hash() {
      if (this.file && this.key) {
        console.log(this.file)
        let crypto = require('crypto')
        let hmac = crypto.createHmac('md5', this.key)
        this.hash = hmac.update(this.file).digest('hex')
        console.log(this.hash)
      }
    }
  }
}
</script>

<style scoped>

</style>