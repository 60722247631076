<template>
    <div>
        <v-card>
            <div class="d-flex align-center justify-space-between py-2 px-2">
                <div class="ml-2 text-styled">
                    Результаты поиска
                </div>
                <v-btn color="success" outlined
                       @click="export_request(validatedData)">
                    <v-img src="@/assets/excel.svg" contain position="center"
                           class="mr-3"
                           style="width: 24px; height: 24px">

                    </v-img>
                    Экспорт
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div>
                <v-data-table :headers="headers"
                              locale="ru-RU"
                              :items="validatedData"
                              items-per-page-text="Элементов на странице"
                              :items-per-page="data.length > 250 ? 250 : -1"
                              class="elevation-0">
                    <template v-slot:[`item.img_link`]="{ item }">
                        <a href="javascript:void(0)" class="d-block" @click="onRowImageClick(item.img_link)">
                            <v-img :src="item.img_link"
                                   max-width="60"
                                   max-height="60"/>
                        </a>
                    </template>
                    <template v-slot:[`item.src_name`]="{ item }">
                        <div v-if="typeof(item.src_name)   == 'string'">
                            {{ item.src_name }}
                        </div>
                        <a target="_blank" v-else :href="item.src_name.link">
                            {{ item.src_name.name }}
                        </a>
                    </template>
                </v-data-table>
            </div>
        </v-card>
        <v-dialog v-model="imageDialog">
            <v-card>
                <v-img v-if="selectedImageLink" :src="selectedImageLink">

                </v-img>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "TableWithoutActionsView",
    props: {
        data: Array,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Индекс',
                    align: 'start',
                    sortable: true,
                    value: 'ind',
                },
                {text: 'Картинка', value: 'img_link', sortable: false},
                {text: 'Наименование', value: 'name', sortable: true},
                {text: 'Артикул', value: 'art', sortable: true},
                {text: 'Стоимость', value: 'cost', sortable: true},
                {text: 'Валюта', value: 'currency', sortable: false},
                {text: 'Наличие', value: 'availability', sortable: true},
                {text: 'Количество', value: 'quantity', sortable: true},
                {text: 'Источник', value: 'src_name', sortable: true},
            ],
            selectedImageLink: null,
            imageDialog: false,
            exportResp: null,
        }
    },
    methods: {
        onRowImageClick(img_link) {
            this.imageDialog = true;
            this.selectedImageLink = img_link;
        },
        export_request(data) {
            let exp_data = {
                "export_data": data
            }
            this.$store.dispatch('export', exp_data)
                .then((resp) => {
                    this.exportResp = resp
                })
                .catch(err => {
                    this.loading = false
                    console.log(err)
                })
        }
    },
    watch: {
        imageDialog: function (val) {
            if (!val) {
                this.selectedImageLink = null;
            }
        },
    },
    computed: {
            validatedData() {
                if (this.data.length !== 0) {
                    let validData = JSON.parse(JSON.stringify(this.data));
                    validData.forEach(element => {
                        element.cost = parseFloat(element.cost).toFixed(2)
                        switch (element.availability) {
                            case true:
                                element.availability = 'В наличии'
                                break
                            case false:
                                element.availability = 'Нет в наличии'
                                break
                            case null:
                                element.availability = 'Нет информации'
                                break
                        }
                        if (element.quantity === -1) {
                            element.quantity = 'Много'
                        }
                        switch (element.currency) {
                            case 'RUB':
                                element.currency = 'Рубли'
                                break
                            case 'USD':
                                element.currency = 'Доллары'
                                break
                            case 'EUR':
                                element.currency = 'Евро'
                                break
                        }
                    })
                    return validData
                }
                return []
            },
        },
}
</script>

<style scoped>

</style>