<template>
  <v-app>
    <v-main>
      <div class="fhd fwd d-flex align-center justify-center">
        <login-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import LoginView from "../components/LoginView";
export default {
  name: "LoginPage",
  components: {LoginView},
  created() {
        if(this.$store.state.tokenValidStatus) this.$router.push('/')
  }
}
</script>

<style scoped>

</style>