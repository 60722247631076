import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from "../pages/LoginPage";
import SearchView from "../components/SearchView";
import SuppliersView from "../components/SuppliersView";
import MainPage from "../pages/MainPage";
import HashPage from "../pages/HashPage";
import CartView from "../components/CartView";
import ExcelSearchView from "../components/ExcelSearchView";
import NewWaitingListView from "../components/NewWaitingListView";
import ExcelSearchResultView from "../components/ExcelSearchResultView";
import ApiSearchResultsView from "@/components/ApiSearchResultsView";
import CompetitorsSearchView from "@/components/CompetitorsSearchView.vue";
import CompetitorsView from "@/components/CompetitorsView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        props: {LoginPage: null}
    },
    {
        path: '/hash',
        name: 'Hash',
        component: HashPage
    },
    {
        path: '/',
        name: 'main',
        component: MainPage,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/search',
                name: 'main_search',
                component: SearchView
            },
            {
                path: '/competitors_search',
                name: 'competitors_search',
                component: CompetitorsSearchView
            },
            {
                path: '/competitors',
                name: 'competitors',
                component: CompetitorsView
            },
            {
                path: '/excel_search',
                name: 'excel_search',
                component: ExcelSearchView
            },
            {
                path: '/suppliers',
                name: 'suppliers',
                component: SuppliersView
            },
            {
                path: '/cart',
                name: 'cart',
                component: CartView
            },
            {
                path: '/waiting_list',
                name: 'waiting_list',
                component: NewWaitingListView
            },
            {
                path: '/excel_results',
                name: 'excel_results',
                component: ExcelSearchResultView
            },
            {
                path: '/api_results',
                name: 'api_results',
                component: ApiSearchResultsView
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token')) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
