<template>
  <v-form @submit.prevent="login">
    <v-alert
      dense
      max-width="400"
      class="align-center"
      type="error"
      v-if="errorMessage"
    >
        {{ errorMessage }}
    </v-alert>
    <v-card max-width="400">
      <v-card-title>
        Вход
      </v-card-title>
      <v-card-text>
        <v-text-field outlined
                      dense
                      prepend-inner-icon="person"
                      clearable
                      v-model="username"
                      label="Логин"
        />
        <v-text-field outlined
                      dense hide-details
                      prepend-inner-icon="lock"
                      clearable
                      v-model="password"
                      label="Пароль"
                      type="password"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit">
          Войти
          <v-icon right>login</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      errorMessage: '',
      username: "",
      password: "",
    }
  },
  methods: {
    login() {
      let user = {
        username: this.username,
        password: this.password,
      }
      this.$store.dispatch('login', user)
          .then(() => this.$router.push('/search'))
          .catch(err => {
            console.log(err.status)
            this.errorMessage = "Введите корректные данные для авторизации"
            })
    }
  },
  created() {
    if(this.$route.params.errorMessage) this.errorMessage = this.$route.params.errorMessage
  }
}
</script>

<style scoped>

</style>