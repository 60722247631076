<template>
  <div class="container">
    <div v-if="shortCart.length !==0">
      <h1>Корзина</h1>
      <v-row align="center" justify="center">
        <v-dialog width="400" overlay-opacity="0.1" v-model="clearDialog"
                  content-class="elevation-0"
                  persistent :retain-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="my-4" v-bind="attrs" v-on="on" elevation="0" dark v-if="!loading" color="danger">Очистить
              корзину
            </v-btn>
          </template>
          <v-card class="fwd">
            <v-card-title>Подтвердите действие</v-card-title>
            <v-card-text class="text-left pb-0">Вы действительно хотите очистить корзину?
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-end">
              <v-btn text class="mr-2" color="danger" dark @click="clearDialog=false">
                Нет
              </v-btn>
              <v-btn text color="success" @click="cart_clear_request">
                Да
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar :timeout="10000" v-model="clearSnackbar" outlined :color="clearStatus ? 'success' : 'danger'">
          <span v-if="clearStatus">Корзина успешно очищена</span>
          <span v-else>Во время очистки корзины возникла ошибка</span>
        </v-snackbar>
        <v-snackbar :timeout="10000" v-model="AutoCartSnack" outlined :color="'danger'">
          <span>Возникла ошибка при авторизации на сайте поставщика</span>
        </v-snackbar>
        <v-btn class="mx-2 my-4" @click="cart_refresh_request" elevation="0" v-if="!loading" color="primary">Обновить
          корзину
        </v-btn>
        <v-btn @click="revoke_task" class="my-4" dark v-if="loading" elevation="0" color="danger">Отменить</v-btn>
        <v-btn v-if="!loading" color="success" outlined
               @click="cart_export_request(validatedCartTable)">
          <v-img src="@/assets/excel.svg" contain position="center"
                 class="mr-3"
                 style="width: 24px; height: 24px">

          </v-img>
          Экспорт
        </v-btn>
      </v-row>
    </div>
    <h1 v-else>Корзина пуста</h1>
    <v-alert
        :value="refreshAlert"
        color="error"
        dark
        class="mt-4"
        border="top"
        icon="error"
    > {{ refreshAlertText }}
    </v-alert>
    <v-alert
        :value="autoCartAlert"
        color="error"
        dark
        class="mt-4"
        border="top"
        icon="error"
    > {{ autoCartAlertText }}
    </v-alert>
    <v-container v-if="loading">
      <v-progress-circular
          color="primary"
          :size="75"
          :width="7"
          :value="progress"
      >{{ progress }}%
      </v-progress-circular>
      <h3 class="pt-2">Запрос обрабатывается. Приблизительное время ожидания: 1 минута.</h3>
    </v-container>
    <!--    <v-container v-if="autoCartLoading">-->
    <!--      <v-progress-circular-->
    <!--          color="primary"-->
    <!--          :size="75"-->
    <!--          :width="7"-->
    <!--          :value="progress"-->
    <!--      >{{ progress }}%-->
    <!--      </v-progress-circular>-->
    <!--      <h3 class="pt-2">Запрос обрабатывается. Приблизительное время ожидания: 1 минута.</h3>-->
    <!--    </v-container>-->
    <v-item-group multiple v-if="shortCart.length !==0 && !loading">
      <v-container>
        <v-item class="ma-2" v-for="(supplier, key, ind) in validatedCartTable" :key="ind" v-slot="{ active, toggle }">
          <v-card v-if="supplier.length !== 0" :color="active ? 'primary' : 'white'">
            <v-card
                color="transparent"
                class="d-flex pl-2 pr-2"
                @click="toggle"
                v-if="supplier.length>0"
            >
              <h2>{{ key }} ({{ supplier.length }}) {{ countSupplierCartCost(supplier) }}</h2>
              <v-spacer/>
              <v-icon v-if="active">
                remove
              </v-icon>
              <v-icon v-else>
                add
              </v-icon>
            </v-card>
            <v-expand-transition mode="out-in">
              <div v-if="active">
                <div style="height: 8px"></div>
                <v-card
                    class="mx-2"
                >
                  <div class="d-flex align-center justify-space-between py-2 px-2">
                    <div class="ml-2 text-styled">
                      <!--                      {{ countSupplierCartCost(supplier) }}-->
                    </div>
                    <v-spacer></v-spacer>
                    <v-dialog width="400" overlay-opacity="0.1" v-model="AutoCartDialog"
                              content-class="elevation-0"
                              :retain-focus="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-2" v-bind="attrs" outlined v-on="on" elevation="0"
                               @click="clearAutoCartData(key)" v-if="isAutoCart(key)" dark
                               color="primary">
                          <v-icon class="mr-2">shopping_cart</v-icon>
                          Добавить в корзину на сайте
                        </v-btn>
                      </template>
                      <v-card class="fwd">
                        <v-form v-if="loginDialog" @submit.prevent="checkSupplierCart">
                          <v-card max-width="400">
                            <v-card-title>
                              Введите данные для входа на сайт поставщика
                            </v-card-title>
                            <v-card-text>
                              <v-text-field outlined
                                            dense
                                            prepend-inner-icon="person"
                                            clearable
                                            v-model="username"
                                            label="Логин"
                              />
                              <v-text-field outlined
                                            dense hide-details
                                            prepend-inner-icon="lock"
                                            clearable
                                            v-model="password"
                                            label="Пароль"
                                            type="password"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="danger" @click="AutoCartDialog=false" dark type="submit">
                                Отмена
                              </v-btn>
                              <v-btn color="primary" type="submit">
                                Войти
                                <v-icon right>login</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-form>
                        <v-card class="fwd" v-if="checkDialog">
                          <v-progress-circular
                              color="primary"
                              :size="75"
                              indeterminate
                              :width="7"
                              v-if="checkLoading"></v-progress-circular>
                          <div>
                            Проверяем есть ли, что в корзине...
                          </div>
                        </v-card>
                        <v-card v-if="cartIsNotEmptyDialog" class="fwd">
                          <v-card-title>Подтвердите действие</v-card-title>
                          <v-card-text class="text-left pb-0">
                            <a target="_blank" :href="checkStatus.link">
                              Корзина поставщика {{ currentSupplier }}
                            </a> не пуста, добавить новые товары?
                          </v-card-text>
                          <v-card-actions class="d-flex align-center justify-end">
                            <v-btn text class="mr-2" color="danger" dark @click="AutoCartDialog=false">
                              Нет
                            </v-btn>
                            <v-btn text color="success" @click="autoCartRequest">
                              Да
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                        <!--                        <v-card-title>Подтвердите действие</v-card-title>-->
                        <!--                        <v-card-text class="text-left pb-0">Вы действительно хотите очистить корзину?-->
                        <!--                        </v-card-text>-->
                        <!--                        <v-card-actions class="d-flex align-center justify-end">-->
                        <!--                          <v-btn text class="mr-2" color="danger" dark @click="clearDialog=false">-->
                        <!--                            Нет-->
                        <!--                          </v-btn>-->
                        <!--                          <v-btn text color="success" @click="cart_clear_request">-->
                        <!--                            Да-->
                        <!--                          </v-btn>-->
                        <!--                        </v-card-actions>-->
                      </v-card>
                    </v-dialog>
                    <v-btn class="mx-2 my-4" @click="supplierToClear=key; supplierCartDeleteDialog = true" elevation="0"
                           dark color="danger">
                      Очистить
                      корзину поставщика
                    </v-btn>
                    <div class="mx-1"/>
                    <v-btn color="success" outlined
                           @click="export_request(key, supplier)">
                      <v-img src="@/assets/excel.svg" contain position="center"
                             class="mr-3"
                             style="width: 24px; height: 24px">

                      </v-img>
                      Экспорт
                    </v-btn>
                  </div>
                  <v-divider></v-divider>
                  <v-data-table :headers="headers"
                                locale="ru-RU"
                                :items="supplier"
                                items-per-page-text="Элементов на странице"
                                :items-per-page="supplier.length > 250 ? 250 : -1"
                                class="elevation-0">
                    <template v-slot:[`item.img`]="{ item }">
                      <a href="javascript:void(0)" class="d-block" @click="onRowImageClick(item.img)">
                        <v-img :src="item.img"
                               max-width="60"
                               max-height="60"/>
                      </a>
                    </template>
                    <template v-slot:[`item.src`]="{ item }">
                      <div v-if="typeof(item.src)   == 'string'">
                        {{ item.src }}
                      </div>
                      <a target="_blank" v-else :href="item.src.link">
                        {{ item.src.name }}
                      </a>
                    </template>
                    <template v-slot:[`item.cartQuantity`]="{ item }">
                      <v-container style="width: 160px; display: flex; flex-direction: row !important">
                        <div>
                          <v-row align="center">
                            <v-btn left icon @click="editQuantity(item, -1)">
                              <v-icon>
                                remove
                              </v-icon>
                            </v-btn>
                            <span>
                      <span>
                        <v-form @submit.prevent="editQuantityByKeyboard(item, item.cartQuantity)">
                        <v-text-field type="number" class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense hide-spin-buttons
                                      v-model="item.cartQuantity"/>
                          </v-form>
                        <!--                      {{ item.cartQuantity }}-->
                      </span>
                    </span>
                            <v-btn
                                right icon @click="editQuantity(item, 1)">
                              <v-icon>
                                add
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </div>
                      </v-container>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-container>
                        <v-row style="width: 170px" align="center"
                               justify="center">
                          <v-btn :disabled="getUpdatingProductInd===item.ind" @click="refreshCartProduct(item)" icon>
                            <v-icon>
                              update
                            </v-icon>
                          </v-btn>
                          <v-dialog width="400" overlay-opacity="0.1" v-model="deleteDialog"
                                    content-class="elevation-0"
                                    persistent :retain-focus="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" @click="objectToDelete=item" v-on="on">
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-card class="fwd">
                              <v-card-title>Подтвердите действие</v-card-title>
                              <v-card-text class="text-left pb-0">Вы действительно хотите удалить
                                "{{ objectToDelete.name }}" из корзины?
                              </v-card-text>
                              <v-card-actions class="d-flex align-center justify-end">
                                <v-btn text class="mr-2" color="danger" dark @click="deleteDialog=false">
                                  Нет
                                </v-btn>
                                <v-btn text color="success" @click="deleteCartProduct(objectToDelete)">
                                  Да
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <!--                          <v-btn icon :disabled="checkWaitingList(item.name, item.code, typeof(item.src)   == 'string' ? item.src : item.src.name)"-->
                          <!--                                 @click="moveCartProductToWaitingList(item)">-->
                          <!--                            <v-icon>-->
                          <!--                              list_alt-->
                          <!--                            </v-icon>-->
                          <!--                          </v-btn>-->
                          <v-btn icon
                                 :disabled="checkWaitingList(item.name, item.code, typeof(item.src)   == 'string' ? item.src : item.src.name)"
                                 @click="check_product_exists(key, item.ind, item)">
                            <v-icon>
                              list_alt
                            </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </template>

                  </v-data-table>
                </v-card>
                <div style="height: 8px"></div>
              </div>
            </v-expand-transition>
          </v-card>
        </v-item>
      </v-container>
    </v-item-group>
    <v-dialog v-model="imageDialog">
      <v-card>
        <v-img v-if="selectedImageLink" :src="selectedImageLink">

        </v-img>
      </v-card>
    </v-dialog>
    <v-dialog width="400" overlay-opacity="0.1" v-model="addToWaitingListDialog"
              content-class="elevation-0" hide-overlay
              persistent :retain-focus="false">
      <v-form @submit.prevent="addToWaitingList(currentProduct)">
        <v-card outlined class="fwd">
          <v-card-title>По какому артикулу искать?</v-card-title>
          <v-card-text class="text-left pb-0">
            <v-text-field
                outlined
                dense
                prepend-inner-icon="person"
                clearable
                v-model="commonCode"
                label="Общий артикул"/>
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-end">
            <v-btn text class="mr-2" color="danger" dark @click="addToWaitingListDialog=false">
              Отмена
            </v-btn>
            <v-btn @click="addToWaitingListDialog = false; moveCartProductToWaitingList(currentProduct)" text
                   color="success">
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog width="400" overlay-opacity="0.1" v-model="supplierCartDeleteDialog"
              content-class="elevation-0"
              persistent :retain-focus="false">
      <v-card class="fwd">
        <v-card-title>Подтвердите действие</v-card-title>
        <v-card-text class="text-left pb-0">Вы действительно хотите очистить корзину поставщика "{{ supplierToClear }}"?
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn text class="mr-2" color="danger" dark @click="supplierCartDeleteDialog=false">
            Нет
          </v-btn>
          <v-btn text color="success" @click="supplier_cart_clear(supplierToClear)">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CartView",
  data() {
    return {
      cart: this.$store.state.cartFullInfo,
      shortCart: this.$store.state.cartShortInfo,
      waitingList: this.$store.state.waitingListShortInfo,
      deleteDialog: false,
      supplierCartDeleteDialog: false,
      supplierToClear: null,
      selectedImageLink: null,
      imageDialog: false,
      clearDialog: false,
      loginDialog: false,
      addToWaitingListDialog: false,
      commonCode: '',
      currentProduct: '',
      checkDialog: false,
      AutoCartDialog: false,
      AutoCartSnack: false,
      clearSnackbar: false,
      updatingProductInd: null,
      exportResp: null,
      username: '',
      password: '',
      failedAdd: [],
      currentSupplier: null,
      loading: false,
      checkStatus: null,
      checkLoading: false,
      cartIsNotEmptyDialog: false,
      cartRefreshResp: [],
      taskId: null,
      checkTaskId: null,
      clearStatus: null,
      progress: 0,
      objectToDelete: {name: '', code: '', src: ''},
      headers: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'code', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src', sortable: true},
        {text: 'Дата обновления товара', value: 'refrTime', sortable: true},
        {text: 'Дата добавления в корзину', value: 'addTime', sortable: true},
        {text: 'Количество в корзине', value: 'cartQuantity', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    get_cart_full_info() {
      this.$store.dispatch('cart_full_info')
          .then((res) => {
            console.log(res)
            // console.log(this.dataFetched)
            this.cart = this.$store.state.cartFullInfo
          })
          .catch(err => console.log(err))
      this.$store.dispatch('cart_short_info')
          .then((res) => {
            console.log(res)
            // console.log(this.dataFetched)
            this.shortCart = this.$store.state.cartShortInfo
          })
          .catch(err => console.log(err))
    },
    isAutoCart(key) {
      return this.cart.extra.allowed_auto_cart.indexOf(key) > -1
    },

    clearAutoCartData(key) {
      this.username = ''
      this.password = ''
      this.AutoCartDialog = false
      this.checkLoading = false
      this.checkDialog = false
      this.cartIsNotEmptyDialog = false
      this.checkStatus = null
      this.currentSupplier = key
      this.checkSupplierCart()
    },
    checkWaitingList(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    autoCartRequest() {
      this.AutoCartDialog = false
      this.loading = true
      this.$store.dispatch('auto_cart_request', {
        login: this.username,
        password: this.password, supplier_name: this.currentSupplier
      })
          .then((id) => {
            this.taskId = id
            this.autoCartRequestResult()
          })
          .catch(err => {
            console.log(err.type)

          })
    },

    autoCartRequestResult() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('auto_cart_request_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.loading = false
                this.progress = 0
                this.failedAdd = res
                console.log(this.failedAdd)
              }
            })
            .catch(err => {
              console.log(err)

            })
        this.delay(2000).then(() => this.autoCartRequestResult())
      }
      // setTimeout(this.cart_refresh_result_request, 2000)
      // }
    },

    checkSupplierCart() {
      this.loginDialog = false
      this.AutoCartDialog = true
      this.checkLoading = true
      this.checkDialog = true
      this.checkStatus = null
      this.$store.dispatch('check_supplier_cart_is_empty', {
        login: this.username,
        password: this.password, supplier_name: this.currentSupplier
      })
          .then((id) => {
            this.checkTaskId = id
            this.checkSupplierCartResult()
          })
          .catch(err => {
            console.log(err.type)

          })
    },
    check_product_exists(outInd, inInd, item) {
      let product_data = {'product_data': this.cart[outInd][inInd - 1].data}
      this.$store.dispatch('check_product_exists', product_data)
          .then((resp) => {
            console.log(resp)
            if (resp['status']) {
              this.moveCartProductToWaitingList(item, '-')

            } else {
              this.currentProduct = product_data
              this.addToWaitingListDialog = true
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    checkSupplierCartResult() {
      // while(this.loading) {
      if (this.checkLoading) {
        this.$store.dispatch('check_supplier_cart_is_empty_result', {task_id: this.checkTaskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.checkLoading = false
                this.progress = 0
                this.checkStatus = res
                console.log(res)

                if (this.checkStatus['status'] === 'failed_to_login') {
                  this.AutoCartDialog = false
                  this.AutoCartSnack = true
                } else {
                  if (this.checkStatus['status'] === true) {
                    this.checkDialog = false
                    this.autoCartRequest()
                  }
                  if (this.checkStatus['status'] === false) {
                    this.checkDialog = false
                    this.cartIsNotEmptyDialog = true
                  }
                }
              }
            })
            .catch(err => {
              console.log(err)

            })
        this.delay(2000).then(() => this.checkSupplierCartResult())
      }
      // setTimeout(this.cart_refresh_result_request, 2000)
      // }
    },
    countSupplierCartCost(items) {
      let cost = {
        'RUB': 0,
        'USD': 0,
        'EUR': 0,
      }
      items.forEach(item => {
        if (item.currency === 'Рубли') {
          cost['RUB'] += (item.cost * item.cartQuantity)
        }
        if (item.currency === 'Доллары') {
          cost['USD'] += (item.cost * item.cartQuantity)
        }
        if (item.currency === 'Евро') {
          cost['EUR'] += (item.cost * item.cartQuantity)
        }
      })
      return `Цена товаров в корзине: ${cost.RUB} Рублей, ${cost.USD} Долларов, ${cost.EUR} Евро`
    },
    checkCart(name, code, srcName) {
      // let res = this.cart.filter(element => {
      //   return element.name === name && element.code === code && element.src === srcName
      // })
      // return !!res.length;
      console.log(name, code, srcName)
      return false
    },
    getCartProductByName(name, code, src) {
      return this.shortCart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    deleteCartProduct(product) {
      console.log(product)
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('delete_cartProduct', {
        'id': cartProduct.id,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    supplier_cart_clear(supplier_name) {
      // console.log(product)
      // let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('delete_cart_by_supplier_name', {
        'name': supplier_name,
      })
          .then((resp) => {
            console.log(resp)
            this.supplierCartDeleteDialog = false
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    moveCartProductToWaitingList(product) {
      product = product.product_data
      console.log(product)
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('move_cartProduct_to_waitingList', {
        'id': cartProduct.id,
        'common_code': this.commonCode,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
            this.$store.dispatch('waiting_list_short_info')
                .then(resp => this.shortWaitingList = resp)
                .catch(err => console.log(err))
            this.$store.dispatch('waiting_list_full_info')
                .then(resp => this.shortWaitingList = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
      this.commonCode = ''
    },
    refreshCartProduct(product) {
      console.log(product)
      this.updatingProductInd = product.ind
      console.log(product.ind === this.updatingProductInd)
      let cartProduct = this.getCartProductByName(product.name, product.code, typeof (product.src) == 'string' ? product.src : product.src.name)[0]
      this.$store.dispatch('refresh_cartProduct', {
        'id': cartProduct.id,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
      this.updatingProductInd = null
    },
    formatString(datetime) {
      let y = new Intl.DateTimeFormat('ru', {year: 'numeric'}).format(datetime);
      let m = new Intl.DateTimeFormat('ru', {month: '2-digit'}).format(datetime);
      let d = new Intl.DateTimeFormat('ru', {day: '2-digit'}).format(datetime);
      let H = new Intl.DateTimeFormat('ru', {hour: '2-digit'}).format(datetime);
      let M = new Intl.DateTimeFormat('ru', {minute: '2-digit'}).format(datetime);
      if (M.length === 1) M = '0' + M
      return `${d}.${m}.${y} ${H}:${M}`
    },
    cart_refresh_request() {
      this.loading = true
      this.cartRefreshResp = []
      this.$store.dispatch('refresh_cart')
          .then((id) => {
            this.taskId = id
            this.cart_refresh_result_request()
          })
          .catch(err => console.log(err))
    },

    cart_clear_request() {
      this.clearDialog = false
      this.$store.dispatch('clear_cart')
          .then((status) => {
            this.clearStatus = status
            this.clearDialog = true
            this.$store.dispatch('cart_short_info')
                .then(resp => this.shortCart = resp)
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },

    revoke_task() {
      if (this.loading) {
        console.log('Revoking Task...')
        this.$store.dispatch('revoke_task', {task_id: this.taskId})
            .then((res) => {
              if (res === true) {
                this.loading = false
                this.isRevoked = res
                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
      }
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    cart_refresh_result_request() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('refresh_cart_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.$store.dispatch('cart_short_info')
                    .then(resp => this.shortCart = resp)
                    .catch(err => console.log(err))

                this.$store.dispatch('cart_full_info')
                    .then(resp => this.cart = resp)
                    .catch(err => console.log(err))
                this.loading = false
                this.progress = 0
                this.cartRefreshResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.cart_refresh_result_request())
      }
      // setTimeout(this.cart_refresh_result_request, 2000)
      // }
    },
    export_request(key, data) {
      let exp_data = {
        "export_data": {},
        "type": "supplier"
      }
      exp_data['export_data'][key] = data
      this.$store.dispatch('export_cart', exp_data)
          .then((resp) => {
            this.exportResp = resp
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    cart_export_request(data) {
      let exp_data = {
        "export_data": data,
        "type": "total"
      }
      // console.log(exp_data)
      this.$store.dispatch('export_cart', exp_data)
          .then((resp) => {
            this.exportResp = resp
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
  }
  ,
  computed: {
    getUpdatingProductInd() {
      return this.updatingProductInd
    }
    ,
    validatedCart() {
      if (this.cart.length !== 0) {
        let cart = JSON.parse(JSON.stringify(this.cart));
        delete cart['extra']
        for (const [key] of Object.entries(cart)) {
          // if (key !== 'extra') {
          let ind = 1
          cart[key].forEach(element => {
            element.addDatetime = this.formatString(new Date(element.addDatetime))
            element.refreshDatetime = this.formatString(new Date(element.refreshDatetime))
            element = element.data
            element.ind = ind
            element.cost = parseFloat(element.cost).toFixed(2)
            switch (element.availability) {
              case true:
                element.availability = 'В наличии'
                break
              case false:
                element.availability = 'Нет в наличии'
                break
              case null:
                element.availability = 'Нет информации'
                break
            }
            if (element.quantity === -1) {
              element.quantity = 'Много'
            }
            switch (element.currency) {
              case 'RUB':
                element.currency = 'Рубли'
                break
              case 'USD':
                element.currency = 'Доллары'
                break
              case 'EUR':
                element.currency = 'Евро'
                break
            }
            ind++
          })
          // }
        }
        return cart
      }
      return []
    }
    ,
    autoCartAlert() {
      return !(this.failedAdd.length === 0)
    }
    ,
    autoCartAlertText() {
      let error = 'Не удалось добавить в корзину следующие элементы: '
      this.failedAdd.forEach(elem => {
        error += elem + ', '
      })
      return error.slice(0, error.length - 2)
    }
    ,
    refreshAlert() {
      return !(this.cartRefreshResp.length === 0)
    }
    ,

    refreshAlertText() {
      let error = 'Не удалось обновить следующие элементы: '
      this.cartRefreshResp.forEach(elem => {
        error += elem + ', '
      })
      return error.slice(0, error.length - 2)
    }
    ,
    validatedCartTable() {
      let data = {}
      for (const [key] of Object.entries(this.cart)) {
        if (key !== 'extra') {
          data[key] = []
          this.validatedCart[key].forEach(element => {
            let temp = {}
            temp.ind = element.data.ind
            temp.name = element.name
            temp.code = element.code
            temp.img = element.data.img_link
            temp.cost = element.data.cost
            temp.currency = element.data.currency
            temp.availability = element.data.availability
            temp.quantity = element.data.quantity
            temp.cartQuantity = element.quantity
            temp.src = element.data.src_name
            temp.addTime = element.addDatetime
            temp.refrTime = element.refreshDatetime
            data[key].push(temp)
          })
        }
      }
      console.log(data)
      return data
    }
  }
  ,
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    }
    ,
  }
  ,
// watch: {
//   validatedCartTable: function (val) {
//     let len = 0
//     val.forEach(el => {
//       len+=el.length
//     })
//     if(len < this.$store.state.cartShortInfo.length) {
//       console.log(this.$store.state.cartFullInfo)
//     }
//     else {
//       console.log('Cart is ok')
//     }
//   }
// },
  mounted() {
    console.log('Mounted cart')
    this.get_cart_full_info()
  }
  ,
// beforeDestroy() {
//   this.dataFetched = false
// }
}
</script>

<style scoped>

</style>