// import Vue from 'vue'
import Axios from 'axios'

// Axios.defaults.baseURL =

export const axios = Axios.create({
    baseURL: '/api'
})
// export default axios
// Vue.use(axios)
