import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        // dark: true,
        themes: {
            light: {
                blue: '#007bff',
                indigo: '#6610f2',
                purple: '#6f42c1',
                pink: '#e83e8c',
                red: '#dc3545',
                orange: '#fd7e14',
                yellow: '#ffc107',
                green: '#28a745',
                teal: '#20c997',
                cyan: '#17a2b8',
                white: '#fff',
                gray: '#868e96',
                darkgray: '#343a40',
                primary: '#FAC72B',
                secondary: '#868e96',
                success: '#28a745',
                info: '#17a2b8',
                warning: '#ffc107',
                danger: '#dc3545',
                light: '#f8f9fa',
                dark: '#343a40'
            },
            dark: {

            }
        }
    }
});
