<template>
  <div class="container">
    <v-card>
      <v-form @submit.prevent="search_request" class="d-flex align-center">
        <v-menu offset-y
                content-class="mt-3"
                transition="slide-y-transition"
                :nudge-width="200"
                :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2"
                   icon
                   v-bind="attrs"
                   v-on="on">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            <v-card class="">
              <v-card-title>Тип поиска</v-card-title>
              <v-card-text>
                <v-switch
                    class="mt-0"
                    v-model="searchCompetitors"
                    label="Искать у конкурентов"
                   ></v-switch>
                <v-switch
                    class="mt-0"
                    v-model="searchAll"
                    :label="searchSwitchLabel"
                    @change="filterSwitchTracker"></v-switch>
                <v-expand-transition mode="out-in">
                  <v-list v-if="!searchAll" dense class="pa-0">
                    <v-list-item v-for="(item, index) in suppliers" :key="index" class="px-0">
                      <v-list-item-action>
                        <v-checkbox @change="filterCheckboxTracker(item.supplier)"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title>{{ item.supplier }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
        <v-text-field
            class="fh py-2"
            single-line
            :disabled="loading"
            hide-details
            prepend-inner-icon="search"
            clearable
            v-model="toSearch"
            label="Поиск"
        />
        <div class="mx-2">
          <v-btn color="primary" type="submit" v-if="!loading" elevation="0">Найти</v-btn>
          <v-btn color="danger" v-else dark @click="revoke_task" elevation="0">Отменить</v-btn>
        </div>
      </v-form>
    </v-card>
    <div class="mt-6">
      <!--      <h3 class="float-left pr-2 pt-0">Поиск:</h3>-->
      <!--      <v-radio-group v-model="searchType">-->
      <!--        <v-radio label="По названию" :value="true"/>-->
      <!--        <v-radio label="По коду" :value="false"/>-->
      <!--      </v-radio-group>-->
    </div>
    <div>
      <!--      <div v-if="filterAlert">-->
      <v-alert
          :value="filterAlert"
          color="error"
          dark
          border="top"
          icon="error">
        Не было выбрано ни 1 источника для поиска информации
      </v-alert>
      <v-alert
          :value="searchAlert"
          color="error"
          dark
          border="top"
          icon="error"
      > Поле поиска не должно быть пустым
      </v-alert>
      <!--      </div>-->
      <div class="mb-3" v-if="competitorsLoading">
        <v-progress-linear indeterminate color="primary">

        </v-progress-linear>
        <h3 class="pt-2">Поиск у конкурентов...</h3>
      </div>
      <div class="mb-3" v-if="competitorsSearchResp===null">
        <h1>У конкурентов ничего не найдено</h1>
      </div>
      <div  v-if="competitorsSearchResp!==null && competitorsSearchResp.length!== 0">
        <v-card class="mb-6">
            <div class="d-flex align-center justify-space-between py-2 px-2">
            <div class="ml-2 text-styled">
            Конкуренты
          </div> </div>
            <v-divider></v-divider>
        <v-row justify="start" class="mt-0 d-flex">
            <v-col cols="12" md="4" v-for="(competitor, ind) in competitorsSearchResp" :key="ind">
              <v-btn color="primary" @click="activateCompDialog(competitor)">
                  {{competitor.name}} (от {{competitor.data[0].cost.toFixed(2)}})
              </v-btn>
            </v-col>
        </v-row>
            </v-card>
      </div>
      <div v-if="loading">
        <v-progress-circular
            color="primary"
            :size="75"
            :width="7"
            :value="progress"
        >{{ progress }}%
        </v-progress-circular>
        <h3 class="pt-2">Поиск у поставщиков...</h3>
      </div>

      <!--      {{ // searchResp }}-->
      <div v-if="searchResp==null">
        <h1>У поставщиков ничего не найдено</h1>
      </div>
      <v-card v-else-if="searchResp!==null&&searchResp.length !== 0">
        <div class="d-flex align-center justify-space-between py-2 px-2">
          <div class="ml-2 text-styled">
            Результаты поиска
          </div>
          <v-btn color="success" outlined
                 @click="export_request(validatedData)">
            <v-img src="@/assets/excel.svg" contain position="center"
                   class="mr-3"
                   style="width: 24px; height: 24px">

            </v-img>
            Экспорт
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div>
          <v-data-table :headers="headers"
                        locale="ru-RU"
                        :items="validatedData"
                        items-per-page-text="Элементов на странице"
                        :items-per-page="searchResp.length > 250 ? 250 : -1"
                        class="elevation-0">
            <template v-slot:[`item.img_link`]="{ item }">
              <a href="javascript:void(0)" class="d-block" @click="onRowImageClick(item.img_link)">
                <v-img :src="item.img_link"
                       max-width="60"
                       max-height="60"/>
              </a>
            </template>
            <template v-slot:[`item.src_name`]="{ item }">
              <div v-if="typeof(item.src_name)   == 'string'">
                {{ item.src_name }}
              </div>
              <a target="_blank" v-else :href="item.src_name.link">
                {{ item.src_name.name }}
              </a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
              <span
                  v-if="checkCart(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)">
                <v-container style="width: 160px; display: flex; flex-direction: row !important">
                  <v-row align="center">
                    <v-btn left icon @click="editQuantity(item, -1)">
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                    <span>
                      <span class="">
                        <v-form
                            @submit.prevent="editQuantityByKeyboard(item, cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity)">
                        <v-text-field class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense type="number" hide-spin-buttons
                                      v-model="cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity"/>
                          </v-form>
                      </span>
                    </span>
                    <v-btn
                        right icon @click="editQuantity(item, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>

              </span>

                <v-row style="width: 130px" justify="center" align="center" v-else>
                  <v-btn :disabled="item.quantity===0" icon class="d-flex align-center justify-center"
                         @click="addToCart(item.ind)">
                    <v-icon>
                      shopping_cart
                    </v-icon>
                  </v-btn>

                  <v-btn
                      :disabled="checkWaitingList(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)"
                      icon>
                    <v-icon @click="check_product_exists(item.ind, 'waiting')">
                      list_alt
                    </v-icon>
                  </v-btn>

                </v-row>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog width="400" overlay-opacity="0.1" v-model="addToCartDialog"
                content-class="elevation-0" hide-overlay
                persistent :retain-focus="false">
        <v-card outlined class="fwd">
          <v-card-title>По какому артикулу искать?</v-card-title>
          <v-card-text class="text-left pb-0">
            <v-text-field
                outlined
                dense
                prepend-inner-icon="code"
                clearable
                v-model="commonCode"
                label="Общий артикул"/>
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-end">
            <v-btn text class="mr-2" color="danger" dark @click="addToCartDialog=false">
              Отмена
            </v-btn>
            <v-btn @click="addToCart(currentProduct)" text color="success">
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="400" overlay-opacity="0.1" v-model="addToWaitingListDialog"
                content-class="elevation-0" hide-overlay
                persistent :retain-focus="false">
        <v-form @submit.prevent="addToWaitingList(currentProduct)">
        <v-card outlined class="fwd">
          <v-card-title>По какому артикулу искать?</v-card-title>
          <v-card-text class="text-left pb-0">
            <v-text-field
                outlined
                dense
                prepend-inner-icon="person"
                clearable
                v-model="commonCode"
                label="Общий артикул"/>
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-end">
            <v-btn text class="mr-2" color="danger" dark @click="addToWaitingListDialog=false">
              Отмена
            </v-btn>
            <v-btn @click="addToWaitingList(currentProduct)" text color="success">
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="imageDialog">
        <v-card>
          <v-img v-if="selectedImageLink" :src="selectedImageLink">

          </v-img>
        </v-card>
      </v-dialog>
      <v-dialog v-model="competitorDialog">
        <v-card color="light">
            <v-toolbar dark color="darkgray"
        >

          <v-toolbar-title><h3>{{ competitorDialogData.name }}</h3></v-toolbar-title>
          <v-spacer></v-spacer>
<!--          <v-toolbar-items>-->
            <v-btn
            icon
            color="danger"
            @click="competitorDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
<!--          </v-toolbar-items>-->
        </v-toolbar>
            <table-without-actions-view :data="competitorDialogData.data"/>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TableWithoutActionsView from "@/components/TableWithoutActionsView.vue";

export default {
  name: "SearchView",
    components: {TableWithoutActionsView},
  data() {
    return {
      selectedImageLink: null,
      competitorDialog: false,
      competitorDialogData: [],
      imageDialog: false,
      addToWaitingListDialog: false,
      addToCartDialog: false,
      commonCode: '',
      currentProduct: '',
      toSearch: '',
      taskId: '',
      competitorsTaskId: '',
      isRevoked: false,
      progress: 0,
      filterAlert: false,
      searchCompetitors: false,
      searchAlert: false,
      competitorsLoading: false,
      headers: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img_link', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'art', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src_name', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      searchResp: [],
      competitorsSearchResp: [],
      exportResp: null,
      loading: false,
      searchAll: true,
      cart: [],
      waitingList: [],
      cartChanged: false,
      waitingListChanged: false,
      suppliers: [],
      parsers: 'all',
    }
  },
  methods: {
    addToCart(ind) {
      console.log(this.searchResp[ind - 1])
      let product_data = {'product_data': this.searchResp[ind - 1]}
      if (this.commonCode.length !== 0) {
        this.addToCartDialog = false
        this.currentProduct = null
        product_data['product_data'].common_code = this.commonCode
        this.commonCode = ''
      }
      this.$store.dispatch('add_to_cart', product_data)
          .then((resp) => {
            console.log(resp)
            this.cartChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    activateCompDialog(data) {
        this.competitorDialogData = data
        this.competitorDialog = true
    },
    addToWaitingList(product_data) {
      if (this.commonCode.length !== 0) {
        this.addToWaitingListDialog = false
        this.currentProduct = null
        product_data['product_data'].common_code = this.commonCode
        this.commonCode = ''
      }
      this.$store.dispatch('add_to_waiting_list', product_data)
          .then((resp) => {
            console.log(resp)
            this.waitingListChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },

    check_product_exists(ind, dialog_type) {
      console.log(this.searchResp[ind - 1])
      let product_data = {'product_data': this.searchResp[ind - 1]}
      this.$store.dispatch('check_product_exists', product_data)
          .then((resp) => {
            console.log(resp)
            if (resp['status']) {
              if (dialog_type === 'waiting') this.addToWaitingList(product_data)
              if (dialog_type === 'cart') this.addToCart(product_data)

            } else {
              this.currentProduct = product_data
              if (dialog_type === 'waiting') this.addToWaitingListDialog = true
              if (dialog_type === 'cart') this.addToCartDialog = true
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },

    getCartProductByName(name, code, src) {
      return this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    getWaitingListProductByName(name, code, src) {
      return this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    isNothingToAdd(item) {
      return this.getCartProductByName(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)[0].quantity >= item.quantity
    },
    isNothingToAddToWaitingList(item) {
      return this.getWaitingListProductByName(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)[0].quantity >= item.quantity
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then((res) => {
                  this.cart = res
                  console.log(res)
                })
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then((res) => {
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantityWaitingList(product, change) {
      let cartProduct = this.getWaitingListProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_waitingListProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('waiting_list_short_info')
                .then((res) => {
                  this.waitingList = res
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },

    search_request() {
      if (typeof this.parsers == "object" && this.parsers.length === 0) {
        this.filterAlert = true
        return
      } else {
        this.filterAlert = false
      }
      if (this.toSearch.length === 0) {
        this.searchAlert = true
        return
      } else {
        this.searchAlert = false
      }
      this.loading = true
      if(this.searchCompetitors) this.competitorsLoading = true
      this.searchResp = []
      this.competitorsSearchResp = []
      let search_data = {
        "search_data": this.toSearch,
        "parsers": this.parsers
      }
      this.$store.dispatch('search', search_data)
          .then((id) => {
            this.taskId = id
            this.search_result_request()
          })
          .catch(err => console.log(err))
       if(this.searchCompetitors) this.$store.dispatch('separated_competitors_search', search_data)
          .then((id) => {
            this.competitorsTaskId = id
            this.comp_search_result_request()
          })
          .catch(err => console.log(err))},

    revoke_task() {
      if (this.loading) {
        console.log('Revoking Task...')
        this.$store.dispatch('revoke_task', {task_id: this.taskId})
            .then((res) => {
              if (res === true) {
                this.loading = false
                this.isRevoked = res

                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => console.log(err))
      }
      this.searchResp = []
      if (this.competitorsLoading) {
        console.log('Revoking competitors Task...')
        this.$store.dispatch('revoke_task', {task_id: this.competitorsTaskId})
            .then((res) => {
              if (res === true) {
                this.competitorsLoading = false
                this.isRevoked = res
                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => console.log(err))
      }
      this.competitorsSearchResp = []
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    filterCheckboxTracker(supplier) {
      if (this.parsers.includes(supplier)) this.parsers.splice(this.parsers.indexOf(supplier), 1)
      else this.parsers.push(supplier)
      console.log(this.parsers)
    },

    checkCart(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    checkWaitingList(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },

    cartElementQuantity(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },
    cartElementQuantityIndex(name, code, srcName) {
      let res = this.cart.findIndex(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res
    },
    waitingListElementQuantity(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },

    filterSwitchTracker(cond) {
      if (cond) this.parsers = 'all'
      else this.parsers = []
    },
    search_result_request() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('search_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.loading = false
                this.progress = 0
                this.searchResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.search_result_request())
      }
      // setTimeout(this.search_result_request, 2000)
      // }
    },
    comp_search_result_request() {
      // while(this.loading) {
      if (this.competitorsLoading) {
        this.$store.dispatch('search_result', {task_id: this.competitorsTaskId})
            .then((res) => {
              console.log('Trying to get results...')
              console.log("COMP",res)
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.competitorsLoading = false
                this.progress = 0
                this.competitorsSearchResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.comp_search_result_request())
      }
      // setTimeout(this.search_result_request, 2000)
      // }
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
    export_request(data) {
      let exp_data = {
        "export_data": data
      }
      this.$store.dispatch('export', exp_data)
          .then((resp) => {
            this.exportResp = resp
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    }
  },
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
    cartChanged: function (val) {
      if (val) {
        this.$store.dispatch('cart_short_info')
            .then((res) => {
              this.cart = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.cartChanged = false
      }
    },
    waitingListChanged: function (val) {
      if (val) {
        this.$store.dispatch('waiting_list_short_info')
            .then((res) => {
              this.waitingList = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.waitingListChanged = false
      }
    }

  },
  computed: {
    searchSwitchLabel() {
      return this.searchAll ? 'По всем' : 'По выбранным'
    }
    ,
    validatedData() {
      if (this.searchResp.length !== 0) {
        let validData = JSON.parse(JSON.stringify(this.searchResp));
        validData.forEach(element => {
          element.cost = parseFloat(element.cost).toFixed(2)
          switch (element.availability) {
            case true:
              element.availability = 'В наличии'
              break
            case false:
              element.availability = 'Нет в наличии'
              break
            case null:
              element.availability = 'Нет информации'
              break
          }
          if (element.quantity === -1) {
            element.quantity = 'Много'
          }
          switch (element.currency) {
            case 'RUB':
              element.currency = 'Рубли'
              break
            case 'USD':
              element.currency = 'Доллары'
              break
            case 'EUR':
              element.currency = 'Евро'
              break
          }
        })
        return validData
      }
      return []
    },

  }
  ,
  created() {
    console.log('Getting suppliers info...')
    this.$store.dispatch('suppliers_info')
        .then((res) => {
          this.suppliers = res
          console.log(res)
        })
        .catch(err => console.log(err))
    this.cart = this.$store.state.cartShortInfo
    this.waitingList = this.$store.state.waitingListShortInfo
  }
}
</script>

<style scoped>

</style>