<template>
  <v-app>
    <v-app-bar dark color="darkgray" max-height="64px">
      <v-app-bar-nav-icon @click="drawer= !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <div class="">
          <router-link to="/search">
            <v-img src="@/assets/parser_logo_text.svg" contain height="64px" width="172px">

            </v-img>
          </router-link>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link :to="{name: 'waiting_list'}">
        <div class="pr-3">Лист ожидания <span
            v-show="this.$store.state.waitingListShortInfo.length > 0">({{
            this.$store.state.waitingListShortInfo.length
          }})</span>
        </div>
      </router-link>
      <router-link :to="{name: 'cart'}">
        <div class="pr-3">Корзина <span
            v-show="this.$store.state.cartShortInfo.length > 0">({{ this.$store.state.cartShortInfo.length }})</span>
        </div>
      </router-link>
      <v-menu offset-y content-class="mt-4" max-width="200" min-width="200">
        <template v-slot:activator="{on, attrs}">
          <v-btn icon v-on="on" v-bind="attrs" dark>
            <v-avatar color="darkgray lighten-1">
              <v-icon>
                person
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card color="white darken-1">
          <v-list>
            <v-list-item dense @click="logout">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center" style="text-align: left">
                  <v-icon left>
                    logout
                  </v-icon>
                  <span>Выйти</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <template v-slot:prepend>
        <div class="pa-3">
          <router-link to="/search">
          <v-img src="@/assets/logo_text.svg">

          </v-img>
            </router-link>
        </div>
      </template>
      <v-list
          nav
          dense
      >
        <v-list-item :to="{name: 'main_search'}">
          <v-list-item-icon>
            <v-icon>search</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Поиск</v-list-item-title>
        </v-list-item>
          <v-list-item :to="{name: 'competitors_search'}">
          <v-list-item-icon>
            <v-icon>store</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Поиск (конкуренты)</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'excel_search'}">
          <v-list-item-icon>
            <v-icon>find_in_page</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Массовый поиск (Excel)</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'excel_results'}">
          <v-list-item-icon>
            <v-icon>bookmarks</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Результаты поиска (Excel)</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'api_results'}">
          <v-list-item-icon>
            <v-icon>settings_applications</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Результаты поиска (1С)</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'suppliers'}">
          <v-list-item-icon>
            <v-icon>person</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Поставщики</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'competitors'}">
          <v-list-item-icon>
            <v-icon>person_off</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Конкуренты</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'cart'}">
          <v-list-item-icon>
            <v-icon>shopping_cart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Корзина</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'waiting_list'}">
          <v-list-item-icon>
            <v-icon>list_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Лист ожидания</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-fade-transition mode="out-in" group leave-absolute>
        <router-view :key="$route.path"/>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      drawer: false,
      cartLength: 0
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    logo_click() {
      this.$router.replace('/search')
    }
  },
  mounted() {
    this.$store.dispatch('is_token_valid')
        .then(() => {
          console.log('Token valid')
        })
        .catch(() => {
          this.$store.dispatch('logout')
              .then(() => {
                this.$router.push({
                  name: 'Login',
                  params: {errorMessage: 'Ваш токен более не валиден, введите данные для авторизации заново'}
                })
              })
        })
    this.$store.dispatch('cart_short_info')
        .then((res) => {
          console.log(res)
          console.log(this.$store.state.cartShortInfo)
        })
        .catch(err => console.log(err))
    this.$store.dispatch('waiting_list_short_info')
        .then((res) => {
          console.log(res)
          console.log(this.$store.state.waitingListShortInfo)
        })
        .catch(err => console.log(err))
  }
}
</script>

<style scoped>

</style>