<template>
  <v-container>
    <h3 v-if="results.length === 0">Результатов поиска нет</h3>
    <v-container v-if="results.length !== 0">
      <h3>Результаты поиска</h3>
      <v-item-group multiple>

        <v-container>
          <v-item class="ma-2" v-for="(results, outInd) in validatedResultsByPage" :key="outInd"
                  v-slot="{ active, toggle }">
            <v-card :color="active ? 'primary' : 'white'">
              <v-card
                  color="transparent"
                  class="d-flex pl-2 pr-2"
                  @click="toggle"
              >
                <h2>{{ `${results.name} (Арт.: ${results.code}) >= ${results.quantity}; Цена закупки - ${results.buy_cost} руб. от ${formatString(results.add_datetime)}` }}</h2>
                <v-spacer/>
                <v-icon v-if="active">
                  remove
                </v-icon>
                <v-icon v-else>
                  add
                </v-icon>
              </v-card>
              <v-expand-transition mode="out-in">
                <div
                    v-if="active"
                >
                  <v-btn class="mt-2"  @click="activateDeleteDialog(results.id, results.name, results.code, formatString(results.add_datetime))" dark color="danger">
                    Удалить
                  </v-btn>
                  <div style="height: 8px"></div>
                  <v-card class="mx-2">
                    <h3 v-if="results.data.length === 0">Ничего не найдено</h3>
                    <v-data-table v-else :headers="resHeaders"
                                                locale="ru-RU"
                                                :items="results.data"
                                                items-per-page-text="Элементов на странице"
                                                :items-per-page="results.data.length > 250 ? 250 : -1"
                                                class="elevation-0">
                                    <template v-slot:[`item.img_link`]="{ item }">
                                      <a href="javascript:void(0)" class="d-block"
                                         @click="onRowImageClick(item.img_link)">
                                        <v-img :src="item.img_link"
                                               max-width="60"
                                               max-height="60"/>
                                      </a>
                                    </template>
                                    <template v-slot:[`item.src_name`]="{ item }">
                                      <div v-if="typeof(item.src_name)   == 'string'">
                                        {{ item.src_name }}
                                      </div>
                                      <a target="_blank" v-else :href="item.src_name.link">
                                        {{ item.src_name.name }}
                                      </a>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                      <div>
              <span
                  v-if="checkCart(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)">
                <v-container style="width: 160px; display: flex; flex-direction: row !important">
                  <v-row justify="center" align="center">
                    <v-btn left icon @click="editQuantity(item, -1)">
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                    <span>
                      <v-form
                          @submit.prevent="editQuantityByKeyboard(item, cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity)">
                        <v-text-field class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense type="number" hide-spin-buttons
                                      v-model="cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity"/>
                          </v-form>
                      <!--                      <span class="pr-1 pl-0">-->
                      <!--                      {{-->
                      <!--                          cartElementQuantity(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)-->
                      <!--                        }}</span>-->
                    </span>
                    <v-btn
                        right icon @click="editQuantity(item, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </span>
                <v-row style="width: 130px" justify="center" align="center" v-else>
                  <v-btn :disabled="item.quantity===0" icon
                         class="d-flex align-center justify-center"
                         @click="addToCart(outInd, item.ind,)">
                    <v-icon>
                      shopping_cart
                    </v-icon>
                  </v-btn>

                  <v-btn
                      :disabled="checkWaitingList(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)"
                      icon>
                    <v-icon @click="check_product_exists(outInd, item.ind, 'waiting')">
                      list_alt
                    </v-icon>
                  </v-btn>

                </v-row>
              </div>
            </template>
          </v-data-table>
                  </v-card>
                  <div style="height: 8px"></div>
                </div>
              </v-expand-transition>
            </v-card>
          </v-item>
        </v-container>
      </v-item-group>
    </v-container>
    <pagination-view @pageChanged="page_changed" :elementsCount="results.length" :currentPage="currentPage"/>
    <v-dialog width="400" overlay-opacity="0.1" v-model="deleteDialog"
              content-class="elevation-0"
              persistent :retain-focus="false">
      <v-card class="fwd">
        <v-card-title>Подтвердите действие</v-card-title>
        <v-card-text class="text-left pb-0">Вы действительно хотите удалить результаты о "{{`${objectToDelete.name} (Арт.: ${objectToDelete.code})`}}" от
          {{ objectToDelete.datetime }}?
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn text class="mr-2" color="danger" dark @click="deleteDialog=false">
            Нет
          </v-btn>
          <v-btn text color="success" @click="deleteResult(objectToDelete)">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog">
      <v-card>
        <v-img v-if="selectedImageLink" :src="selectedImageLink">

        </v-img>
      </v-card>
    </v-dialog>
    <v-dialog width="400" overlay-opacity="0.1" v-model="addToWaitingListDialog"
                content-class="elevation-0" hide-overlay
                persistent :retain-focus="false">
        <v-form @submit.prevent="addToWaitingList(currentProduct)">
        <v-card outlined class="fwd">
          <v-card-title>По какому артикулу искать?</v-card-title>
          <v-card-text class="text-left pb-0">
            <v-text-field
                outlined
                dense
                prepend-inner-icon="person"
                clearable
                v-model="commonCode"
                label="Общий артикул"/>
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-end">
            <v-btn text class="mr-2" color="danger" dark @click="addToWaitingListDialog=false">
              Отмена
            </v-btn>
            <v-btn @click="addToWaitingList(currentProduct)" text color="success">
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
  </v-container>
</template>

<script>
import PaginationView from "@/components/PaginationView";
export default {
  name: "ApiSearchResultsView",
  components: {PaginationView},
  data() {
    return {
      results: [],
      currentPage: 1,
      resHeaders: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img_link', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'art', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src_name', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      selectedImageLink: null,
      imageDialog: false,
      cart: [],
      deleteDialog: false,
      objectToDelete: {id: '', datetime: ''},
      cartChanged: false,
      waitingList: [],
      waitingListChanged: false,
      addToWaitingListDialog: false,
      commonCode: '',
      currentProduct: '',
    }
  },
  methods: {
    page_changed(e) {
      this.currentPage = e
      console.log(this.validatedResultsByPage)
    },
    getResults() {
      this.$store.dispatch('outapi_search_results')
          .then((res) => {
            // console.log(res)
            // console.log(this.dataFetched)
            this.results = res
          })
          .catch(err => console.log(err))
    },
    formatString(datetime) {
      datetime = new Date(datetime)
      let y = new Intl.DateTimeFormat('ru', {year: 'numeric'}).format(datetime);
      let m = new Intl.DateTimeFormat('ru', {month: '2-digit'}).format(datetime);
      let d = new Intl.DateTimeFormat('ru', {day: '2-digit'}).format(datetime);
      let H = new Intl.DateTimeFormat('ru', {hour: '2-digit'}).format(datetime);
      let M = new Intl.DateTimeFormat('ru', {minute: '2-digit'}).format(datetime);
      if (M.length === 1) M = '0' + M
      return `${d}.${m}.${y} ${H}:${M}`
    },
    addToCart(outInd, inInd) {
      console.log(outInd+((this.currentPage-1)*10))
      console.log(this.results[outInd+((this.currentPage-1)*10)].data[inInd - 1])
      let product_data = {'product_data': this.results[outInd+((this.currentPage-1)*10)].data[inInd - 1]}
      this.$store.dispatch('add_to_cart', product_data)
          .then((resp) => {
            console.log(resp)
            this.cartChanged = true
          })
          .catch(err => {
            // this.loading = false
            console.log(err)
          })
    },
    deleteResult() {
      this.$store.dispatch('delete_api_result_by_id', {
        'id': this.objectToDelete.id,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.getResults()
          })
          .catch(err => console.log(err))
    },
    activateDeleteDialog(id, name, code, datetime) {
      this.objectToDelete.id = id
      this.objectToDelete.name = name
      this.objectToDelete.code = code
      this.objectToDelete.datetime = datetime
      this.deleteDialog = true
    },
    getCartProductByName(name, code, src) {
      return this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then((res) => {
                  this.cart = res
                  console.log(res)
                })
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then((res) => {
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    cartElementQuantityIndex(name, code, srcName) {
      let res = this.cart.findIndex(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res
    },
    checkCart(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    cartElementQuantity(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
    checkWaitingList(name, code, srcName) {
      let res = this.waitingList.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    check_product_exists(outInd, inInd, dialog_type) {
        // console.log(this.results[outInd+((this.currentPage-1)*10)].data[inInd - 1])
      console.log(this.results[outInd+((this.currentPage-1)*10)].data[inInd-1])
      console.log(dialog_type)
      let product_data = {'product_data': this.results[outInd+((this.currentPage-1)*10)].data[inInd-1]}
      this.$store.dispatch('check_product_exists', product_data)
          .then((resp) => {
            console.log(resp)
            if (resp['status']) {
              if (dialog_type === 'waiting') this.addToWaitingList(product_data)
              // if (dialog_type === 'cart') this.addToCart(product_data)

            } else {
              this.currentProduct = product_data
              if (dialog_type === 'waiting') this.addToWaitingListDialog = true
              // if (dialog_type === 'cart') this.addToCartDialog = true
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    addToWaitingList(product_data) {
      if (this.commonCode.length !== 0) {
        this.addToWaitingListDialog = false
        this.currentProduct = null
        product_data['product_data'].common_code = this.commonCode
        this.commonCode = ''
      }
      this.$store.dispatch('add_to_waiting_list', product_data)
          .then((resp) => {
            console.log(resp)
            this.waitingListChanged = true
          })
          .catch(err => {
            // this.loading = false
            console.log(err)
          })
    },
  },
  computed: {
    validatedResults() {
      if (this.results.length !== 0) {
        let validated = JSON.parse(JSON.stringify(this.results));
        // console.log(validated)
        validated.forEach(result => {
          let ind = 1
          // console.log(result)
          result.data.forEach(element => {
            element.ind = ind
            element.cost = parseFloat(element.cost).toFixed(2)
            switch (element.availability) {
              case true:
                element.availability = 'В наличии'
                break
              case false:
                element.availability = 'Нет в наличии'
                break
              case null:
                element.availability = 'Нет информации'
                break
            }
            if (element.quantity === -1) {
              element.quantity = 'Много'
            }
            switch (element.currency) {
              case 'RUB':
                element.currency = 'Рубли'
                break
              case 'USD':
                element.currency = 'Доллары'
                break
              case 'EUR':
                element.currency = 'Евро'
                break
            }
            ind++
          })
        })
        console.log('Validated')
        console.log(validated)
        return validated
      }
      return []
    },
    validatedResultsByPage() {
      return this.validatedResults.slice((this.currentPage - 1) * 10, (this.currentPage) * 10)

    },
  },
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
    cartChanged: function (val) {
      if (val) {
        this.$store.dispatch('cart_short_info')
            .then((res) => {
              this.cart = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.cartChanged = false
      }
    },
    waitingListChanged: function (val) {
      if (val) {
        this.$store.dispatch('waiting_list_short_info')
            .then((res) => {
              this.waitingList = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.waitingListChanged = false
      }
    }
  },
  mounted() {
    this.getResults()
    this.cart = this.$store.state.cartShortInfo
    this.waitingList = this.$store.state.waitingListShortInfo
  }
}
</script>

<style scoped>

</style>