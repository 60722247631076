<template>
  <v-container>
    <h3 v-if="excelResults.length === 0">Результатов поиска нет</h3>
    <v-container v-if="excelResults.length !== 0">
      <h3>Результаты поиска</h3>
      <v-item-group multiple>

        <v-container>
          <v-item class="ma-2" v-for="(results, outInd) in validatedSearchResult" :key="outInd"
                  v-slot="{ active, toggle }">
            <v-card :color="active ? 'primary' : 'white'">
              <v-card
                  color="transparent"
                  class="d-flex pl-2 pr-2"
                  @click="toggle"
              >
                <h2>{{ formatString(results.add_datetime) }}</h2>
                <v-spacer/>
                <v-icon v-if="active">
                  remove
                </v-icon>
                <v-icon v-else>
                  add
                </v-icon>
              </v-card>
              <v-expand-transition mode="out-in">
                <div
                    v-if="active"
                ><v-row class="mt-3 mb-2" align="center" justify="center">
                  <v-btn class="mr-3"  @click="activateDeleteDialog(results.id, formatString(results.add_datetime))" dark color="danger">
                    Удалить
                  </v-btn>
                    <v-btn color="success" outlined
                       @click="export_request(results.id)">
                    <v-img src="@/assets/excel.svg" contain position="center"
                           class="mr-3"
                           style="width: 24px; height: 24px">

                    </v-img>
                    Экспорт
                </v-btn></v-row>
                  <div style="height: 8px"></div>
                  <v-card class="mx-2">


                    <v-item-group multiple>
                      <v-container>
                        <v-item class="ma-2" v-for="(result, midInd) in results.data" :key="midInd"
                                v-slot="{ active, toggle }">
                          <v-card :color="active ? 'primary' : 'white'">
                            <v-card
                                color="transparent"
                                class="d-flex pl-2 pr-2"
                                @click="toggle"
                            >
                              <h2>{{ result.name }})</h2>
                              <v-spacer/>
                              <v-icon v-if="active">
                                remove
                              </v-icon>
                              <v-icon v-else>
                                add
                              </v-icon>
                            </v-card>
                            <v-expand-transition mode="out-in">
                              <div
                                  v-if="active"
                              >
                                <div style="height: 8px"></div>
                                <v-card class="mx-2">
                                  <h3 v-if="result.data.length === 0">Ничего не найдено</h3>
                                  <v-data-table v-else :headers="resHeaders"
                                                locale="ru-RU"
                                                :items="result.data"
                                                items-per-page-text="Элементов на странице"
                                                :items-per-page="result.data.length > 250 ? 250 : -1"
                                                class="elevation-0">
                                    <template v-slot:[`item.img_link`]="{ item }">
                                      <a href="javascript:void(0)" class="d-block"
                                         @click="onRowImageClick(item.img_link)">
                                        <v-img :src="item.img_link"
                                               max-width="60"
                                               max-height="60"/>
                                      </a>
                                    </template>
                                    <template v-slot:[`item.src_name`]="{ item }">
                                      <div v-if="typeof(item.src_name)   == 'string'">
                                        {{ item.src_name }}
                                      </div>
                                      <a target="_blank" v-else :href="item.src_name.link">
                                        {{ item.src_name.name }}
                                      </a>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                      <div>
              <span
                  v-if="checkCart(item.name, item.art, typeof(item.src_name)   == 'string' ? item.src_name : item.src_name.name)">
                <v-container style="width: 160px; display: flex; flex-direction: row !important">
                  <v-row justify="center" align="center">
                    <v-btn left icon @click="editQuantity(item, -1)">
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                    <span>
                      <v-form
                          @submit.prevent="editQuantityByKeyboard(item, cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity)">
                        <v-text-field class="mt-6" outlined style="width: 50px; justify-self: center"
                                      dense type="number" hide-spin-buttons
                                      v-model="cart[cartElementQuantityIndex(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)].quantity"/>
                          </v-form>
                      <!--                      <span class="pr-1 pl-0">-->
                      <!--                      {{-->
                      <!--                          cartElementQuantity(item.name, item.art, typeof (item.src_name) == 'string' ? item.src_name : item.src_name.name)-->
                      <!--                        }}</span>-->
                    </span>
                    <v-btn
                        right icon @click="editQuantity(item, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </span>
                                        <v-row style="width: 130px" justify="center" align="center" v-else>
                                          <v-btn :disabled="item.quantity===0" icon
                                                 class="d-flex align-center justify-center"
                                                 @click="addToCart(outInd, midInd, item.ind,)">
                                            <v-icon>
                                              shopping_cart
                                            </v-icon>
                                          </v-btn>

                                        </v-row>
                                      </div>
                                    </template>
                                  </v-data-table>
                                </v-card>
                                <div style="height: 8px"></div>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </v-item>
                      </v-container>
                    </v-item-group>
                  </v-card>
                  <div style="height: 8px"></div>
                </div>
              </v-expand-transition>
            </v-card>
          </v-item>
        </v-container>
      </v-item-group>
    </v-container>
    <v-dialog width="400" overlay-opacity="0.1" v-model="deleteDialog"
              content-class="elevation-0"
              persistent :retain-focus="false">
      <v-card class="fwd">
        <v-card-title>Подтвердите действие</v-card-title>
        <v-card-text class="text-left pb-0">Вы действительно хотите удалить результаты от
          {{ objectToDelete.datetime }}?
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn text class="mr-2" color="danger" dark @click="deleteDialog=false">
            Нет
          </v-btn>
          <v-btn text color="success" @click="deleteResult(objectToDelete)">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog">
      <v-card>
        <v-img v-if="selectedImageLink" :src="selectedImageLink">

        </v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ExcelSearchResultView",
  data() {
    return {
      selectedImageLink: null,
      imageDialog: false,
      // addToWaitingListDialog: false,
      // addToCartDialog: false,
      // commonCode: '',
      // currentProduct: '',
      cart: [],
      deleteDialog: false,
      objectToDelete: {id: '', datetime: ''},
      // waitingList: [],
      cartChanged: false,
      // waitingListChanged: false,
      // file: null,
      // invalidFile: false,
      // excelData: [],
      // loading: false,
      // taskId: null,
      // progress: 0,
      excelResults: [],
      resHeaders: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img_link', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'art', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src_name', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      headers: [
        {
          text: 'Артикул',
          align: 'start',
          sortable: false,
          value: 'code',
        },
        {text: 'Количество', value: 'quantity', sortable: false},
      ],
    }
  },
  methods: {
    getExcelResults() {
      this.$store.dispatch('excel_search_results')
          .then((res) => {
            console.log(res)
            // console.log(this.dataFetched)
            this.excelResults = res
          })
          .catch(err => console.log(err))
    },
    export_request(data) {
            let exp_data = {
                "id": data
            }
            this.$store.dispatch('export_excel_search', exp_data)
                .then((resp) => {
                    this.exportResp = resp
                })
                .catch(err => {
                    console.log(err)
                })
        },
    editQuantityByKeyboard(product, value) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': value
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then(resp => this.cart = resp)
                .catch(err => console.log(err))

            this.$store.dispatch('cart_full_info')
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    cartElementQuantityIndex(name, code, srcName) {
      let res = this.cart.findIndex(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res
    },
    deleteResult() {
      this.$store.dispatch('delete_excel_result_by_id', {
        'id': this.objectToDelete.id,
      })
          .then((resp) => {
            console.log(resp)
            this.deleteDialog = false
            this.getExcelResults()
          })
          .catch(err => console.log(err))
    },
    addToCart(outInd, midInd, inInd) {
      console.log(this.excelResults[outInd].data[midInd].data[inInd - 1])
      let product_data = {'product_data': this.excelResults[outInd].data[midInd].data[inInd - 1]}
      this.$store.dispatch('add_to_cart', product_data)
          .then((resp) => {
            console.log(resp)
            this.cartChanged = true
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    },
    formatString(datetime) {
      datetime = new Date(datetime)
      let y = new Intl.DateTimeFormat('ru', {year: 'numeric'}).format(datetime);
      let m = new Intl.DateTimeFormat('ru', {month: '2-digit'}).format(datetime);
      let d = new Intl.DateTimeFormat('ru', {day: '2-digit'}).format(datetime);
      let H = new Intl.DateTimeFormat('ru', {hour: '2-digit'}).format(datetime);
      let M = new Intl.DateTimeFormat('ru', {minute: '2-digit'}).format(datetime);
      if (M.length === 1) M = '0' + M
      return `${d}.${m}.${y} ${H}:${M}`
    },
    getCartProductByName(name, code, src) {
      return this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === src
      });
    },
    editQuantity(product, change) {
      let cartProduct = this.getCartProductByName(product.name, product.art, typeof (product.src_name) == 'string' ? product.src_name : product.src_name.name)[0]
      this.$store.dispatch('edit_cartProduct_quantity', {
        'id': cartProduct.id,
        'new_quantity': cartProduct.quantity + change
      })
          .then((resp) => {
            console.log(resp)
            this.$store.dispatch('cart_short_info')
                .then((res) => {
                  this.cart = res
                  console.log(res)
                })
                .catch(err => console.log(err))
            this.$store.dispatch('cart_full_info')
                .then((res) => {
                  console.log(res)
                })
                .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
    },
    activateDeleteDialog(id, datetime) {
      this.objectToDelete.id = id
      this.objectToDelete.datetime = datetime
      this.deleteDialog = true
    },
    checkCart(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return !!res.length;
    },
    cartElementQuantity(name, code, srcName) {
      let res = this.cart.filter(element => {
        return element.name === name && element.code === code && element.src === srcName
      })
      return res[0].quantity
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
  },
  computed: {
    validatedSearchResult() {
      if (this.excelResults.length !== 0) {
        let validated = JSON.parse(JSON.stringify(this.excelResults));
        validated.forEach(request => {
          request.data.forEach(element => {
            let ind = 1
            if (element.data === null) element.data = []
            element.data.forEach(elem => {
              elem.ind = ind
              elem.cost = parseFloat(elem.cost).toFixed(2)
              switch (elem.availability) {
                case true:
                  elem.availability = 'В наличии'
                  break
                case false:
                  elem.availability = 'Нет в наличии'
                  break
                case null:
                  elem.availability = 'Нет информации'
                  break
              }
              if (elem.quantity === -1) {
                elem.quantity = 'Много'
              }
              switch (elem.currency) {
                case 'RUB':
                  elem.currency = 'Рубли'
                  break
                case 'USD':
                  elem.currency = 'Доллары'
                  break
                case 'EUR':
                  elem.currency = 'Евро'
                  break
              }
              ind++
            })

          })
        })
        return validated
      }
      return []
    }
  },
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
    cartChanged: function (val) {
      if (val) {
        this.$store.dispatch('cart_short_info')
            .then((res) => {
              this.cart = res
              console.log(res)
            })
            .catch(err => console.log(err))
        this.cartChanged = false
      }
    },
  },
  mounted() {
    this.getExcelResults()
    this.cart = this.$store.state.cartShortInfo
  }
}
</script>

<style scoped>

</style>