import Vue from 'vue'
import Vuex from 'vuex'
import {axios} from '@/plugins/axios'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {},
        searchStatus: false,
        cartShortInfo: [],
        cartFullInfo: [],
        waitingListShortInfo: [],
        waitingListFullInfo: [],
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        set_status(state, status) {
            state.status = status
        },
        revoke_status(state, status) {
            state.searchStatus = status
        },
        cart_short_info(state, info) {
            state.cartShortInfo = info
        },
        cart_full_info(state, info) {
            state.cartFullInfo = info
        },
        waiting_list_short_info(state, info) {
            state.waitingListShortInfo = info
        },
        waiting_list_full_info(state, info) {
            state.waitingListFullInfo = info
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/users/login/', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        // console.log(token)
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err.response.data)
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        check_supplier_cart_is_empty({commit}, check_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(check_data)
                axios.post("/search/auto_cart_worker_check/", check_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        check_supplier_cart_is_empty_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/auto_cart_worker_check_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== false) {
                            resolve(resp.data.result)
                        } else {
                            resolve(resp.data.state.current)
                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        auto_cart_request({commit}, add_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(add_data)
                axios.post("/search/auto_cart_worker/", add_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        auto_cart_request_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/auto_cart_worker_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== false) {
                            resolve(resp.data.result)
                        } else {
                            resolve(resp.data.state.current)
                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        search({commit}, search_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(search_data)
                axios.post("/search/search/", search_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        competitors_search({commit}, search_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(search_data)
                axios.post("/competitors/search/", search_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        separated_competitors_search({commit}, search_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(search_data)
                axios.post("/competitors/sep_search_result/", search_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        excel_search({commit}, search_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(search_data)
                axios.post("/search/excel_search/", search_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        search_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/search_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== false) {
                            resolve(resp.data.result)
                        } else {
                            if (resp.data.state) resolve(resp.data.state.current)
                            resolve(0)

                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        revoke_task({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/revoke/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('revoke_status', true)
                        resolve(resp.data.revoke)
                    })
                    .catch(err => {
                        commit('revoke_status', false)
                        reject(err)
                    })
            })
        },
        export({commit}, export_data) {
            console.log(export_data)
            return new Promise((resolve, reject) => {
                axios.post("/search/export/", export_data, {
                    responseType: "blob",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp)
                        let blob = new Blob([resp.data], {type: resp.headers['content-type']})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        export_excel_search({commit}, export_data) {
            console.log(export_data)
            return new Promise((resolve, reject) => {
                axios.post("/search/export_excel_search/", export_data, {
                    responseType: "blob",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp)
                        let blob = new Blob([resp.data], {type: resp.headers['content-type']})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        export_cart({commit}, export_data) {
            console.log(export_data)
            return new Promise((resolve, reject) => {
                axios.post("/search/export_cart/", export_data, {
                    responseType: "blob",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp)
                        let blob = new Blob([resp.data], {type: resp.headers['content-type']})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        excel_example({commit}) {
            return new Promise((resolve, reject) => {
                axios.get("/search/excel_example/", {
                    responseType: "blob",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp)
                        let blob = new Blob([resp.data], {type: resp.headers['content-type']})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        export_wl({commit}, export_data) {
            console.log(export_data)
            return new Promise((resolve, reject) => {
                axios.post("/search/export_wl/", export_data, {
                    responseType: "blob",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp)
                        let blob = new Blob([resp.data], {type: resp.headers['content-type']})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },

        suppliers_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/suppliers_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        competitors_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/competitors/competitors_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        cart_short_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/cart_short_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('cart_short_info', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('cart_short_info', [])
                        reject(err)
                    })
            })
        },
        cart_full_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/cart_full_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('cart_full_info', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('cart_full_info', [])
                        reject(err)
                    })
            })
        },
        check_product_exists({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/check_product_exists/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        add_to_cart({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/add_to_cart/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        add_to_cart_by_id({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/add_to_cart_by_id/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        clear_cart({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/clear_cart/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.status)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        clear_waiting_list({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/clear_waiting_list/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.status)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        edit_cartProduct_quantity({commit}, cartProduct_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/edit_cartproduct_quantity/", cartProduct_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        delete_cartProduct({commit}, cartProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/delete_cartproduct/", cartProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        delete_cart_by_supplier_name({commit}, supplier_name) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/delete_cart_by_supplier_name/", supplier_name, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_cartProduct({commit}, cartProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/refresh_cartproduct/", cartProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        move_cartProduct_to_waitingList({commit}, cartProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/move_cartproduct_to_waiting_list/", cartProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_cart({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/refresh_cart/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_cart_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/refresh_cart_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== false) {
                            resolve(resp.data.result)
                        } else {
                            resolve(resp.data.state.current)
                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        waiting_list_short_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/waiting_list_short_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('waiting_list_short_info', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('waiting_list_short_info', [])
                        reject(err)
                    })
            })
        },
        waiting_list_full_info({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/waiting_list_full_info/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        // commit('waiting_list_full_info', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('waiting_list_full_info', [])
                        reject(err)
                    })
            })
        },
        add_to_waiting_list({commit}, product_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/add_to_waiting_list/", product_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },

        edit_waitingListProduct_quantity({commit}, waitingListProduct_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/edit_waitinglistproduct_quantity/", waitingListProduct_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', err)
                        reject(err)
                    })
            })
        },
        delete_waitingListProduct({commit}, waitingListProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/delete_waitinglistproduct/", waitingListProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_waitingListProduct({commit}, waitingListProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/refresh_waitinglistproduct/", waitingListProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_waitingListProduct_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/refresh_waitinglistproduct_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== null) {
                            resolve(resp.data.result)
                        } else {
                            resolve(resp.data.state.current)
                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        move_waitingListProduct_to_cart({commit}, waitingListProduct_id) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/move_waiting_list_product_to_cart/", waitingListProduct_id, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_waiting_list({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/refresh_waiting_list/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data.task_id)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        refresh_waiting_list_result({commit}, task_data) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                console.log(task_data)
                axios.post("/search/refresh_waiting_list_result/", task_data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        console.log(resp.data)
                        if (resp.data.result !== false) {
                            resolve(resp.data.result)
                        } else {
                            resolve(resp.data.state.current)
                        }
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        is_token_valid({commit}) {
            return new Promise((resolve, reject) => {
                axios.get("/users/token_valid_status/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.status)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        excel_search_results({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/search/get_excel_search_results/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        outapi_search_results({commit}) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.get("/out_api/results/", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        delete_excel_result_by_id({commit}, excelResultId) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/search/delete_excel_search_result/", excelResultId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
        delete_api_result_by_id({commit}, apiResultId) {
            return new Promise((resolve, reject) => {
                // const token =
                // axios.defaults.headers.common['Authorization'] = token
                axios.post("/out_api/delete/", apiResultId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.token}`,
                    }
                })
                    .then(resp => {
                        commit('set_status', true)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('set_status', false)
                        reject(err)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },
    modules: {},
    plugins: [createPersistedState()],
})
