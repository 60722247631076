<template>
  <v-container>
<!--    {{maxPage}}-->
    <v-row justify="center" align="center">
      <v-btn icon @click="prevPage" :disabled="currentPage === 1">
        <v-icon>chevron_left</v-icon>
      </v-btn>
    {{currentPage}}
      <v-btn icon @click="nextPage" :disabled="currentPage>=maxPage">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PaginationView",
  props: [
      'elementsCount',
      'currentPage'
  ],
  data() {
    return {
      // currentPage: 1
    }
  },
  methods: {
    nextPage() {
      // this.currentPage+=1
      this.$emit('pageChanged', this.currentPage+1)
    },
    prevPage() {
      // this.currentPage-=1
      this.$emit('pageChanged', this.currentPage-1)
    }
  },
  computed: {
    maxPage() {
      let maxPage = Math.floor(this.elementsCount/10)
      if(this.elementsCount%10 !== 0) maxPage+=1
      return maxPage
    }
  }
}
</script>

<style scoped>

</style>