<template>
  <div>
<!--    <v-input></v-input>-->
    <v-file-input outlined
                  dense hide-details
                  prepend-inner-icon="attach_file" prepend-icon="" label="Файл" @change="file_process"></v-file-input>
<!--    <input type="file" @change="file_process">-->
  </div>
</template>

<script>
export default {
  name: "FileInputView",

  methods: {
    file_process(event) {
      console.log(event)
      if (event) {
        const reader = new FileReader()
        let fileByteArray = [];
        reader.readAsText(event, 'utf-8')
        reader.onloadend = (evt) => {
          if (evt.target.readyState === FileReader.DONE) {
            fileByteArray = evt.target.result
            this.$emit('file', {res: fileByteArray})
          }
          // let md5 = require("md5")
        }
      }
      else {
        this.$emit('file', {res: null})
      }
    }
    // this.$emit('file', {res: event.target.files[0]})
  }
}

</script>

<style scoped>

</style>