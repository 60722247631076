<template>
  <div class="container">
    <v-card>
      <v-form @submit.prevent="search_request" class="d-flex align-center">
        <v-menu offset-y
                content-class="mt-3"
                transition="slide-y-transition"
                :nudge-width="200"
                :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2"
                   icon
                   v-bind="attrs"
                   v-on="on">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            <v-card class="">
              <v-card-title>Тип поиска</v-card-title>
              <v-card-text>
                <v-switch
                    class="mt-0"
                    v-model="searchAll"
                    :label="searchSwitchLabel"
                    @change="filterSwitchTracker"></v-switch>
                <v-expand-transition mode="out-in">
                  <v-list v-if="!searchAll" dense class="pa-0">
                    <v-list-item v-for="(item, index) in competitors" :key="index" class="px-0">
                      <v-list-item-action>
                        <v-checkbox @change="filterCheckboxTracker(item.competitor)"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title>{{ item.competitor }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
        <v-text-field
            class="fh py-2"
            single-line
            :disabled="loading"
            hide-details
            prepend-inner-icon="search"
            clearable
            v-model="toSearch"
            label="Поиск"
        />
        <div class="mx-2">
          <v-btn color="primary" type="submit" v-if="!loading" elevation="0">Найти</v-btn>
          <v-btn color="danger" v-else dark @click="revoke_task" elevation="0">Отменить</v-btn>
        </div>
      </v-form>
    </v-card>
    <div class="mt-6">
      <!--      <h3 class="float-left pr-2 pt-0">Поиск:</h3>-->
      <!--      <v-radio-group v-model="searchType">-->
      <!--        <v-radio label="По названию" :value="true"/>-->
      <!--        <v-radio label="По коду" :value="false"/>-->
      <!--      </v-radio-group>-->
    </div>
    <div>
      <!--      <div v-if="filterAlert">-->
      <v-alert
          :value="filterAlert"
          color="error"
          dark
          border="top"
          icon="error">
        Не было выбрано ни 1 источника для поиска информации
      </v-alert>
      <v-alert
          :value="searchAlert"
          color="error"
          dark
          border="top"
          icon="error"
      > Поле поиска не должно быть пустым
      </v-alert>
      <!--      </div>-->
      <div v-if="loading">
        <v-progress-circular
            color="primary"
            :size="75"
            :width="7"
            :value="progress"
        >{{ progress }}%
        </v-progress-circular>
        <h3 class="pt-2">Запрос обрабатывается. Приблизительное время ожидания: 1 минута.</h3>
      </div>

      <!--      {{ // searchResp }}-->
      <div v-if="searchResp==null">
        <h1>Ничего не найдено</h1>
      </div>
      <TableWithoutActionsView v-else-if="searchResp!=null&&searchResp.length !== 0" :data="searchResp"/>


    </div>
  </div>
</template>

<script>
import TableWithoutActionsView from "@/components/TableWithoutActionsView.vue";

export default {
  name: "CompetitorsSearchView",
    components: {TableWithoutActionsView},
  data() {
    return {
      selectedImageLink: null,
      imageDialog: false,
      toSearch: '',
      taskId: '',
      isRevoked: false,
      progress: 0,
      filterAlert: false,
      searchAlert: false,
      headers: [
        {
          text: 'Индекс',
          align: 'start',
          sortable: true,
          value: 'ind',
        },
        {text: 'Картинка', value: 'img_link', sortable: false},
        {text: 'Наименование', value: 'name', sortable: true},
        {text: 'Артикул', value: 'art', sortable: true},
        {text: 'Стоимость', value: 'cost', sortable: true},
        {text: 'Валюта', value: 'currency', sortable: false},
        {text: 'Наличие', value: 'availability', sortable: true},
        {text: 'Количество', value: 'quantity', sortable: true},
        {text: 'Источник', value: 'src_name', sortable: true},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      searchResp: [],
      exportResp: null,
      loading: false,
      searchAll: true,
      competitors: [],
      parsers: 'all',
    }
  },
  methods: {
    search_request() {
      if (typeof this.parsers == "object" && this.parsers.length === 0) {
        this.filterAlert = true
        return
      } else {
        this.filterAlert = false
      }
      if (this.toSearch.length === 0) {
        this.searchAlert = true
        return
      } else {
        this.searchAlert = false
      }
      this.loading = true
      this.searchResp = []
      let search_data = {
        "search_data": this.toSearch,
        "parsers": this.parsers
      }
      this.$store.dispatch('competitors_search', search_data)
          .then((id) => {
            this.taskId = id
            this.search_result_request()
          })
          .catch(err => console.log(err))
    },

    revoke_task() {
      if (this.loading) {
        console.log('Revoking Task...')
        this.$store.dispatch('revoke_task', {task_id: this.taskId})
            .then((res) => {
              if (res === true) {
                this.loading = false
                this.isRevoked = res
                console.log('Revoked successfully')
              } else {
                console.log('Revoke failed')
              }
            })
            .catch(err => console.log(err))
      }
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    filterCheckboxTracker(supplier) {
      if (this.parsers.includes(supplier)) this.parsers.splice(this.parsers.indexOf(supplier), 1)
      else this.parsers.push(supplier)
      console.log(this.parsers)
    },

    filterSwitchTracker(cond) {
      if (cond) this.parsers = 'all'
      else this.parsers = []
    },

    search_result_request() {
      // while(this.loading) {
      if (this.loading) {
        this.$store.dispatch('search_result', {task_id: this.taskId})
            .then((res) => {
              console.log('Trying to get results...')
              if (Number.isInteger(res)) {
                this.progress = res
              } else {
                this.loading = false
                this.progress = 0
                this.searchResp = res
              }
            })
            .catch(err => console.log(err))
        this.delay(2000).then(() => this.search_result_request())
      }
      // setTimeout(this.search_result_request, 2000)
      // }
    },
    onRowImageClick(img_link) {
      this.imageDialog = true;
      this.selectedImageLink = img_link;
    },
    export_request(data) {
      let exp_data = {
        "export_data": data
      }
      this.$store.dispatch('export', exp_data)
          .then((resp) => {
            this.exportResp = resp
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
    }
  },
  watch: {
    imageDialog: function (val) {
      if (!val) {
        this.selectedImageLink = null;
      }
    },
  },
  computed: {
    searchSwitchLabel() {
      return this.searchAll ? 'По всем' : 'По выбранным'
    }
    ,
    validatedData() {
      if (this.searchResp.length !== 0) {
        let validData = JSON.parse(JSON.stringify(this.searchResp));
        validData.forEach(element => {
          element.cost = parseFloat(element.cost).toFixed(2)
          switch (element.availability) {
            case true:
              element.availability = 'В наличии'
              break
            case false:
              element.availability = 'Нет в наличии'
              break
            case null:
              element.availability = 'Нет информации'
              break
          }
          if (element.quantity === -1) {
            element.quantity = 'Много'
          }
          switch (element.currency) {
            case 'RUB':
              element.currency = 'Рубли'
              break
            case 'USD':
              element.currency = 'Доллары'
              break
            case 'EUR':
              element.currency = 'Евро'
              break
          }
        })
        return validData
      }
      return []
    },

  }
  ,
  created() {
    console.log('Getting competitors info...')
    this.$store.dispatch('competitors_info')
        .then((res) => {
          this.competitors = res
          console.log(res)
        })
        .catch(err => console.log(err))
  }
}
</script>

<style scoped>

</style>