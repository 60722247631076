<template>
  <div class="container">
    <v-row align="center" justify="center">
      <v-col sm="12" md="12" lg="6">
        <h2>Поставщики</h2>
        <v-data-table
            v-if="suppliers.length>0"
            :headers="headers"
            :items="suppliers"
            :items-per-page="-1"
            class="elevation-1"
        >
          <template v-slot:[`item.link`]="{ item }">
            <a target="_blank" :href="item.link">
              {{ item.link }}
            </a>
          </template>
        </v-data-table>
        <h2 v-else>Нет информации о поставщиках</h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SuppliersView",
  data() {
    return {
      suppliers: [],
      headers: [
        {
          text: 'Поставщик',
          align: 'center',
          sortable: false,
          value: 'supplier',
          width: '10%'
        },
        {text: 'Сайт', value: 'link', sortable: false, width: '10%', align: 'center',},
      ],
    }
  },
  created() {
    console.log('Getting suppliers info...')
    this.$store.dispatch('suppliers_info')
        .then((res) => {
          this.suppliers = res
          console.log(res)
        })
        .catch(err => console.log(err))
  }
}
</script>

<style scoped>

</style>